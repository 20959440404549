import React, { FC } from 'react';
import { useGetEmployerAccountSettings } from './data/AccountData';
import AccountSettingsForm from './form/AccountSettingsForm';
import { Spinner } from '#root/src/components/Index';

const AccountSettings: FC = () => {
  const { data: accountData, isLoading } = useGetEmployerAccountSettings();

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  if (accountData == null) {
    return null;
  }

  return (
    <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6 px-8 2xl:px-0">
      <h1 className="hp-black font-serif text-display-xs mb-4">Account Settings</h1>
      <div className="w-full p-8 bg-white rounded-lg shadow-md-dark mb-12">
        <AccountSettingsForm account={accountData.account} />
      </div>
    </div>
  );
};

export default AccountSettings;
