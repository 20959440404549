import { Errors } from '#root/src/types';
import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    employer: {
      firstName: string;
      lastName: string;
      city: string;
      country: string;
      position: string;
      phone: string;
      email: string;
      currentPassword?: string;
      password?: string | null;
      passwordConfirmation?: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'email' | 'password' | 'passwordConfirmation' | 'currentPassword'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/employers/accounts');
