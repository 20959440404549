import React, { useState, useRef, forwardRef, useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import searchSelectStyles from '#components/Form/SearchSelect/SearchSelect.module.css';
import InputWrapper, { InputProps } from '../InputWrapper';
import { AutocompleteRequestFunction, AutocompleteOption } from '#root/src/types';

export interface AutocompleteInputRHFProps extends InputProps {
  control: Control<any>;
  name: string;
  autocompleteRequest: AutocompleteRequestFunction;
  placeholder?: string;
  disabled?: boolean;
  selectHandler?: (option: AutocompleteOption) => void;
  recordsLimit?: number;
  className?: string;
}

const AutocompleteInputRHF = forwardRef<HTMLDivElement, AutocompleteInputRHFProps>(
  (
    {
      name: propsName,
      control,
      label,
      error,
      description,
      required,
      placeholder,
      className,
      autocompleteRequest,
      selectHandler = () => {},
      disabled = false,
      recordsLimit = 10,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<AutocompleteOption[]>([]);
    const searchSelectRef = useRef<HTMLDivElement>(null);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const debounce = useCallback((func: () => void, delay: number) => {
      if (debounceTimeout.current != null) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(func, delay);
    }, []);

    const handleSearch = useCallback(
      (value: string) => {
        debounce(() => {
          void (async () => {
            if (value.length >= 3) {
              try {
                const options = await autocompleteRequest({ query: value });
                setOptions(options);
              } catch (error) {
                console.error(error);
              }
            } else {
              setOptions([]);
            }
          })();
        }, 300);
      },
      [autocompleteRequest, debounce]
    );

    const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
      if (searchSelectRef.current != null && !searchSelectRef.current.contains(event.relatedTarget)) {
        setOpen(false);
      }
    };

    const handleFocus = () => setOpen(true);

    return (
      <Controller
        name={propsName}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value = '', name, onBlur } }) => (
          <InputWrapper label={label} error={error} description={description} required={required}>
            {({ id, hasError }) => (
              <div
                id={label}
                aria-labelledby={label}
                aria-disabled={disabled}
                ref={ref ?? searchSelectRef}
                onBlur={handleBlur}
                onFocus={handleFocus}
                className="inline-grid relative"
              >
                <input
                  type="search"
                  id={id}
                  name={name}
                  className={[
                    textBoxStyles.input,
                    hasError ? textBoxStyles['input--error'] : '',
                    className,
                    'w-full',
                  ].join(' ')}
                  autoComplete="off"
                  onChange={(e) => {
                    onChange(e.target.value); // Trigger form state update
                    handleSearch(e.target.value);
                  }}
                  value={value}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  disabled={disabled}
                />

                {open && options.length > 0 && (
                  <div className={searchSelectStyles['option-container']}>
                    {options.slice(0, recordsLimit).map((option) => (
                      <div
                        key={option.id}
                        className={searchSelectStyles.option}
                        tabIndex={0}
                        onClick={() => {
                          onChange(option.name); // Update form value with selected option
                          setOpen(false);
                          selectHandler(option); // Trigger external select handler
                        }}
                      >
                        <span className={searchSelectStyles.option__label}>{option.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </InputWrapper>
        )}
      />
    );
  }
);

AutocompleteInputRHF.displayName = 'AutocompleteInputRHF';

export default AutocompleteInputRHF;
