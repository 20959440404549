import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import * as createTeamMemberAPI from '#api/endpoints/employers/team/addTeamMember';

export interface FormValues extends FieldValues {
  firstName: string;
  lastName: string;
  email: string;
}

export const defaultFormValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const validationSchema = object().shape({
  firstName: string().trim().required('First name is required'),
  lastName: string().trim().required('Last name is required'),
  email: string().trim().required('Email is required'),
});

interface ControllerProps {
  refetch: () => void;
  closeModal: () => void;
}

export const useTeamMemberController = ({ refetch, closeModal }: ControllerProps) => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const { parseErrors } = useParseFormErrors<createTeamMemberAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await createTeamMemberAPI.request({
        teamMember: {
          ...values,
        },
      });
      createSuccessToast({ title: 'Team Settings', text: 'Team member has been successfully added.' });
      refetch();
      closeModal();
    } catch (errors) {
      createErrorToast({ title: 'Team Settings', text: 'Failed to add team member.' });
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm };
};
