import React, { FC } from 'react';
import { EmployerInterviewingCandidate } from '#src/types';
import TalentSponsorCard from '#pages/talentPages/activity/elements/sidebar/TalentSponsorCard';

interface CandidateSidebarProps {
  selectedCandidate: EmployerInterviewingCandidate | null;
  isLoading?: boolean;
}

const CandidateSidebar: FC<CandidateSidebarProps> = ({ selectedCandidate, isLoading = false }) => {
  if (selectedCandidate == null) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-8">
      <div>
        <h5 className="uppercase text-gray-500 font-semibold text-xs tracking-widest mb-4">Your Hiperpool Contact</h5>
        <TalentSponsorCard talentSponsor={selectedCandidate.clientSuccessManager}>
          <p className="text-gray-500 mb-4 font-light">
            Feel free to contact me if you have any questions or if you need support.
          </p>
        </TalentSponsorCard>
      </div>
    </div>
  );
};

export default CandidateSidebar;
