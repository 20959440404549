import React, { FC } from 'react';
import Header from './elements/Header';

const Referrals: FC = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Referrals;
