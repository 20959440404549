import { useEffect } from 'react';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import * as createEmploymentAPI from '#root/src/api/endpoints/talents/profile/employments/createEmployment';
import * as updateEmploymentAPI from '#root/src/api/endpoints/talents/profile/employments/updateEmployment';

export interface FormValues extends FieldValues {
  company: string;
  position: string;
  location: string;
  startDate: string;
  endDate: string | null;
  description: string;
  current: boolean;
}

const defaultFormValues = (
  company: string,
  position: string,
  location: string,
  startDate: string,
  endDate: string | null,
  description: string,
  current: boolean
): FormValues => ({
  company,
  position,
  location,
  startDate,
  endDate,
  description,
  current,
});

const validationSchema = object().shape({
  company: string().required('Company is required'),
  position: string().required('Position is required'),
  startDate: string().required('Start Date is required'),
  endDate: string().when('current', {
    is: false,
    then: string().required('End Date is required'),
  }),
});

interface ControllerProps {
  id?: number;
  company?: string;
  position?: string;
  location?: string;
  startDate?: string;
  endDate?: string | null;
  description?: string;
  current?: boolean;
  successCallback: () => void;
}

export const useEmploymentController = ({
  id = 0,
  company = '',
  position = '',
  location = '',
  startDate = '',
  endDate = '',
  description = '',
  current = false,
  successCallback,
}: ControllerProps) => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(company, position, location, startDate, endDate, description, current),
  });

  const { reset } = formMethods;

  useEffect(() => {
    // Reset the form values when the EmploymentEntry props changes (for example after opening the modal for existing employment)
    reset(defaultFormValues(company, position, location, startDate, endDate, description, current));
  }, [company, position, location, startDate, endDate, description, current, reset]);

  const { parseErrors } = useParseFormErrors<createEmploymentAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const updateRequest = async () => {
      return await updateEmploymentAPI.request({
        id,
        employment: {
          ...values,
        },
      });
    };

    const createRequest = async () => {
      return await createEmploymentAPI.request({
        employment: {
          ...values,
        },
      });
    };

    try {
      await (id !== 0 ? updateRequest() : createRequest());
      successCallback();
      createSuccessToast({ title: 'Employment' });
    } catch (errors) {
      createErrorToast({ title: 'Employment' });
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm };
};
