/* eslint-disable promise/prefer-await-to-then */
import { isFunction } from 'lodash-es';
import axios, { AxiosError, AxiosInstance, AxiosResponse, GenericAbortSignal, ResponseType } from 'axios';

import { transformToCamelCase, transformToSnakeCase } from '#src/lib/caseTransformObject';

// TYPES
type AnyObject = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

type Method = Extract<keyof AxiosInstance, 'get' | 'delete' | 'post' | 'put' | 'patch'>;

type PathType = string | ((data: AnyObject) => string);

interface ApiAction {
  meta: {
    request: {
      method: Method;
      path: string;
    };
  };
  payload: {
    data?: AnyObject;
  };
  responseType: ResponseType;
}

interface ActionParams<Data extends AnyObject | undefined> {
  data: Data;
  method: Method;
  path: PathType;
  options?: Options;
}

interface Options {
  responseType?: ResponseType;
}

interface RequestOptions {
  signal?: GenericAbortSignal;
}

// TYPE GUARDS
const isPostPutOrPatchMethod = (tbd: any): tbd is 'put' | 'patch' | 'post' => ['put', 'patch', 'post'].includes(tbd);

// VARIABLES
const AxiosWithHeader = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

// FUNCTIONS
const configureApi = () => {
  AxiosWithHeader.interceptors.request.use(
    function (config) {
      config.params = {
        ...config.params,
      };

      return config;
    },
    async function (error) {
      return await Promise.reject(error);
    }
  );
};

const handleErrorSideEffects = (error: AxiosError<AnyObject>) => {
  if (error.response != null) {
    if (error.response.status === 401) {
      // TODO: for later on add here possible side effects like redirects or so
    }
  }
};

const makeRequest = async (action: ApiAction, options?: RequestOptions) => {
  const data: AnyObject = transformToSnakeCase(action.payload?.data ?? {});

  if (isPostPutOrPatchMethod(action.meta.request.method)) {
    return await AxiosWithHeader[action.meta.request.method](
      action.meta.request.path,
      { ...data },
      { responseType: action.responseType, signal: options?.signal }
    );
  } else {
    return await AxiosWithHeader[action.meta.request.method](action.meta.request.path, {
      params: data,
      responseType: action.responseType,
      signal: options?.signal,
    });
  }
};

const constructApiActionCreator = <T extends AnyObject | undefined>({
  data,
  method,
  path,
  options,
}: ActionParams<T>): ApiAction => {
  let compiledPath: string;
  if (isFunction(path)) {
    if (data != null) {
      compiledPath = path(data);
    }
    compiledPath = path({});
  } else {
    compiledPath = path;
  }

  compiledPath = compiledPath.startsWith('/') ? compiledPath : '/' + compiledPath;
  return {
    payload: { data },
    meta: { request: { path: 'api' + compiledPath, method } },
    responseType: options?.responseType ?? 'json',
  };
};

const makeFormDataRequest = async (action: ApiAction, options?: RequestOptions) => {
  const data: AnyObject = action.payload?.data ?? {};

  const formData = new FormData();
  const params: AnyObject = {};

  for (const key in data) {
    if (data[key] instanceof File) {
      formData.append(key, data[key]);
    } else {
      params[key] = data[key];
    }
  }

  formData.append('json', JSON.stringify(transformToSnakeCase(params)));

  return await AxiosWithHeader[action.meta.request.method](action.meta.request.path, formData, {
    responseType: action.responseType,
    signal: options?.signal,
  });
};

const constructFormDataApiActionCreator = <T extends AnyObject | undefined>({
  data,
  method,
  path,
  options,
}: ActionParams<T>): ApiAction => {
  let compiledPath: string;
  if (isFunction(path)) {
    if (data != null) {
      compiledPath = path(data);
    }
    compiledPath = path({});
  } else {
    compiledPath = path;
  }

  compiledPath = compiledPath.startsWith('/') ? compiledPath : '/' + compiledPath;
  return {
    payload: { data },
    meta: { request: { path: 'api' + compiledPath, method } },
    responseType: options?.responseType ?? 'json',
  };
};

// EXPORTS
export const GET = 'get';
export const PATCH = 'patch';
export const POST = 'post';
export const PUT = 'put';
export const DELETE = 'delete';

export interface ApiPayloadsCreator<T extends { [K in 'init' | 'success' | 'fail' | 'meta']?: unknown }> {
  init: { payload: T['init']; meta: T['meta'] };
  success: { payload: T['success']; meta: T['meta'] };
  fail: { payload: T['fail']; meta: T['meta'] };
}

// Standard JSON Request Exports
export const createAction = <T extends { success?: unknown; fail?: unknown; init?: AnyObject }>(
  method: Method,
  path: PathType,
  options: Options = {}
) => {
  const apiActionsCreator = (data: T['init'] = {}) =>
    constructApiActionCreator<T['init']>({ data, method, path, options });

  return async (data: T['init'] = {}, requestOptions?: RequestOptions) =>
    await new Promise((resolve: (value: T['success']) => void, reject: (value: T['fail']) => void) => {
      const createdAction = apiActionsCreator(data);
      configureApi();
      makeRequest(createdAction, requestOptions)
        .then((response: AxiosResponse<AnyObject>) => {
          const payload: AnyObject = transformToCamelCase(response.data);
          resolve(payload);
        })
        .catch((error: AxiosError<AnyObject>) => {
          const errorResponse: AnyObject = transformToCamelCase(error.response?.data ?? error);
          handleErrorSideEffects(error);
          if (error.response != null) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ ...errorResponse, status: error.response.status });
          } else {
            if (!axios.isCancel(error)) {
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw error;
            }
          }
        });
    });
};

// FormData Request Exports
export const createFormDataAction = <T extends { success?: unknown; fail?: unknown; init?: AnyObject }>(
  method: Method,
  path: PathType,
  options: Options = {}
) => {
  const apiActionsCreator = (data: T['init'] = {}) =>
    constructFormDataApiActionCreator<T['init']>({ data, method, path, options });
  return async (data: T['init'] = {}, options?: RequestOptions) =>
    await new Promise((resolve: (value: T['success']) => void, reject: (value: T['fail']) => void) => {
      const createdAction = apiActionsCreator(data);
      configureApi();
      makeFormDataRequest(createdAction, options)
        .then((response: AxiosResponse<AnyObject>) => {
          const payload: AnyObject = transformToCamelCase(response.data);
          resolve(payload);
        })
        .catch((error: AxiosError<AnyObject>) => {
          const errorResponse: AnyObject = transformToCamelCase(error.response?.data ?? error);
          handleErrorSideEffects(error);
          if (error.response != null) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ ...errorResponse, status: error.response.status });
          } else {
            throw error;
          }
        });
    });
};
