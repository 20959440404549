import { createAction, PATCH, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { id: number };
  success: never;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(PATCH, `/employers/offers/${params.id}/mark_as_hired`)();
