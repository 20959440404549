import { formatDistanceToNow, differenceInDays, format, parseISO } from 'date-fns';

export const relativeDateFromISO = (isoDateString: string | null): string => {
  if (isoDateString === null) return '';

  try {
    return formatDistanceToNow(parseISO(isoDateString), { addSuffix: true });
  } catch {
    return '';
  }
};

export const formattedDateFromISO = (isoDateString: string | null): string => {
  if (isoDateString === null) return '';

  try {
    return format(parseISO(isoDateString), 'yyyy-MM-dd');
  } catch {
    return '';
  }
};

export const alternativeFormattedDateFromISO = (isoDateString: string | null): string => {
  if (isoDateString === null) return '';

  try {
    const currentDate = new Date();
    const targetDate = parseISO(isoDateString);

    // Check the difference in days between now and the given date
    const daysDiff = differenceInDays(currentDate, targetDate);
    const daysSinceYearStart = differenceInDays(currentDate, new Date(currentDate.getFullYear(), 0, 0));

    if (daysDiff < 14) {
      // If the date is less than 14 days old, format it with a relative date string (e.g., "5 days ago")
      return formatDistanceToNow(targetDate, { addSuffix: true });
    } else if (daysDiff < daysSinceYearStart) {
      // If the date is from same year but more than 14 days old, format it in a 'MMM d' style (e.g., "Aug 5")
      return format(targetDate, 'MMM d');
    } else {
      // Otherwise, format it in a 'MMM d, yyyy' style (e.g., "Aug 5, 2021")
      return format(targetDate, 'MMM d, yyyy');
    }
  } catch {
    return '';
  }
};
