import React, { FC } from 'react';
import { useTeamMemberController } from './FormController';
import { Icon, Modal, Button } from '#components/Index';
import { TextInput } from '#components/Form/Index';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const NewTeamMemberModal: FC<Props> = ({ isOpen, onClose, refetch }) => {
  const { form, submitForm } = useTeamMemberController({ refetch, closeModal: onClose });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col bg-gray-50 w-screen max-w-4xl">
          <div className="pt-8 px-8">
            <div className="flex justify-around items-center pb-2">
              <div className="flex items-center gap-x-8 text-gray-500 text-sm">Team</div>

              <button
                onClick={onClose}
                className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
                aria-label="Close"
              >
                <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
              </button>
            </div>

            <div className="flex items-center justify-between">
              <h1 className="text-hpblack text-display-sm font-serif mb-4">Add a new team member</h1>
            </div>
          </div>

          <div className="flex gap-y-4 flex-col px-8 pb-12">
            <TextInput label="First name" {...register('firstName')} error={errors.firstName?.message} />
            <TextInput label="Last name" {...register('lastName')} error={errors.lastName?.message} />
            <TextInput label="Email" type="email" {...register('email')} error={errors.email?.message} />
          </div>
        </div>

        <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default NewTeamMemberModal;
