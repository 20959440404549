import { TeamMemberForm } from '#root/src/types';
import { createAction, PATCH, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    id: number;
    teamMembers: TeamMemberForm;
  };
  success: never;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(PATCH, `/employers/opportunities/${params.id}/team_members`)(params);
