import React, { FC, useState, useMemo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { createSuccessToast } from '#src/lib/Toasts';
import { request as apiArchiveOpportunity } from '#api/endpoints/employers/opportunities/archiveOpportunity';
import { KeysEnum as opportunityQueryKeys } from '../data/EmployerOpportunityData';
import { Icon, Modal, Button, Selectable } from '#components/Index';

interface Props {
  opportunityId: number;
  isOpen: boolean;
  closeModal: () => void;
}

const options = [
  {
    reason: 'hired',
    label: 'We’ve hired a Hiperpool candidate',
  },
  {
    reason: 'finished',
    label: 'We’ve hired a candidate through another channel',
  },
  {
    reason: 'cancelled',
    label: 'We’ve stopped hiring for this opportunity',
  },
];

const ArchiveOpportunityModal: FC<Props> = ({ opportunityId, isOpen, closeModal }) => {
  const queryClient = useQueryClient();
  const [selectedReason, setSelectedReason] = useState<string | null>(null);

  const archiveButtonDisabled = useMemo(() => selectedReason === null, [selectedReason]);

  const onClose = useCallback(() => {
    setSelectedReason(null);
    closeModal();
  }, [closeModal]);

  const archiveOpportunity = useCallback(async () => {
    if (selectedReason == null) return;

    await apiArchiveOpportunity({ id: opportunityId, archive: { reason: selectedReason } });
    await queryClient.invalidateQueries([
      opportunityQueryKeys.employers,
      opportunityQueryKeys.opportunities,
      opportunityQueryKeys.show,
      opportunityId,
    ]);

    createSuccessToast({ title: 'This opportunity has been archived', text: 'Thank you for your feedback!' });

    onClose();
  }, [opportunityId, selectedReason, queryClient, onClose]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-screen max-w-4xl gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm font-medium">
          <div className="flex flex-col gap-y-3">
            <h2 className="text-display-xs font-serif hp-black text-center">Archive opportunity</h2>

            <p className="hp-black font-light text-md text-center">
              Archiving this opportunity means we will no longer match talent for it. You can reactivate it at any time
              by contacting your Client Success Manager.
            </p>

            <p className="hp-black font-light text-md text-center mt-4">
              Let us know why you’d like to archive this opportunity:
            </p>

            <div className="flex flex-col gap-2 mt-4">
              {options.map((option) => (
                <Selectable
                  key={option.reason}
                  id={`archive-${option.reason}`}
                  type="radio"
                  name="current-situation"
                  size="lg"
                  value={option.reason}
                  onChange={() => setSelectedReason(option.reason)}
                  checked={selectedReason === option.reason}
                >
                  {option.label}
                </Selectable>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-center gap-x-8 mt-8">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={archiveOpportunity} disabled={archiveButtonDisabled}>
              Archive
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ArchiveOpportunityModal;
