import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    profile: {
      firstName: string;
      lastName: string;
      location: string;
      currentEducationId: number | null;
      currentEmploymentId: number | null;
      phone: string | null;
    };
  };
  success: never;
  fail: {
    errors: Errors<'firstName' | 'lastName' | 'location' | 'summary' | 'currentEducationId' | 'currentEmploymentId'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/talents/profiles/details');
