import React, { FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '#components/Index';
import { CandidateNavbar } from './elements/CandidateNavbar';
import { EmployerNavbar } from '../employer/elements/EmployerNavbar';
import { useUserDetails } from '#src/AppData';

interface Props {
  withFooter?: boolean;
}
const CandidateLayout: FC<Props> = ({ withFooter = true }) => {
  const context = useUserDetails();

  const navbar = useMemo(() => {
    if (context.user?.type === 'Employer') {
      return <EmployerNavbar />;
    }

    return <CandidateNavbar />;
  }, [context]);

  return (
    <>
      {navbar}
      <Outlet context={context} />
      {withFooter && <Footer />}
    </>
  );
};

export default CandidateLayout;
