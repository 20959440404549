import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from '#pages/paths';
import { EmployerPotentialCandidate } from '#src/types';
import { Button, Icon } from '#components/Index';

interface PotentialCandidateCardProps {
  candidate: EmployerPotentialCandidate;
}

const PotentialCandidateCard: FC<PotentialCandidateCardProps> = ({ candidate }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(paths.employerCandidatePreview({ id: candidate.id }));
  };

  return (
    <>
      <div className="rounded-lg overflow-clip shadow-md-darkish bg-white transition outline outline-3 -outline-offset-3 outline-green-200/0 relative hover:shadow-md-darker w-full">
        <div className="grid p-6 gap-x-8 items-start sm:flex md:gap-y-6 cursor-pointer" onClick={onClick}>
          <div className="min-w-[100px] min-h-[100px] max-w-[100px] max-h-[100px] rounded-full overflow-hidden justify-center items-center hidden lg:flex">
            <img src={candidate.profile.avatarUrl} alt={candidate.profile.fullName} className="min-w-full min-h-full" />
          </div>

          <div className="flex flex-col gap-y-2">
            <div className="flex items-center gap-x-4 lg:gap-x-0">
              <div className="min-w-[100px] min-h-[100px] max-w-[100px] max-h-[100px] rounded-full overflow-hidden flex justify-center items-center lg:hidden">
                <img
                  src={candidate.profile.avatarUrl}
                  alt={candidate.profile.fullName}
                  className="min-w-full min-h-full"
                />
              </div>

              <div>
                <h3 className="font-serif text-display-xs">{candidate.profile.fullName}</h3>
                <p className="text-lg text-gray-500">{candidate.profile.position}</p>
              </div>
            </div>
            <div>
              <div className="mt-2 text-gray-500 text-md">
                <div className="flex items-center gap-x-2">
                  <div>
                    <Icon.MapPinFill size={5} aria-hidden="true" className="text-green-500" />
                  </div>
                  <span>{candidate.profile.education}</span>
                </div>
                <div className="flex items-center gap-x-2">
                  <div>
                    <Icon.School size={5} aria-hidden="true" className="text-green-500" />
                  </div>
                  <span>{candidate.profile.location}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row flex-wrap bg-gray-50 gap-4 p-4 justify-between items-center">
          <div className="text-gray-500 text-sm font-light">RE: {candidate.opportunity.name}</div>
          <div className="flex items-center justify-around md:justify-normal">
            <Button size="small" to={paths.employerCandidatePreview({ id: candidate.id })}>
              <span className="text-center">Review profile</span>{' '}
              <Icon.ArrowRightLine size={4} aria-hidden="true" className="shrink-0 right-5 max-2xs:absolute" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PotentialCandidateCard;
