import React, { FC, useMemo, useEffect } from 'react';
import { DateTime } from 'luxon';

import { User } from '#src/types';
import { Button, DatePicker } from '#components/Index';
import { MiniSelect } from '#components/Form/Index';
import { useCalendarData } from './CalendarDataProvider';
import BundlesModal from './BundlesModal';
import useCalendarController from './CalendarController';
import { paths, parseParamsToLinkSearch, signInRedirectUrl } from '#pages/paths';
import { useSearchParams } from 'react-router-dom';

// TODO: there is a problem with selecting 00:00 - instead of proposing 24:00 it instead gives 00:00 of the previous day
const transferDateTimeToDate = (dt: DateTime) => {
  // it omits the timezone on Date object
  return new Date(dt.get('year'), dt.get('month') - 1, dt.get('day'), dt.get('hour'), dt.get('minute'), 0);
};

interface Props {
  coachSlug: string;
  openModalAndSetCalendlyUrl: (calendlyUrl: string) => void;
  isLoggedIn: boolean;
  user: User | null;
}

const CalendarForm: FC<Props> = ({ openModalAndSetCalendlyUrl, coachSlug, isLoggedIn = false, user }) => {
  const [searchParams] = useSearchParams();
  const redirectUrlFromQueryParams = searchParams.get('redirectUrl');

  const { dateTime, setDateTime, timezone, setTimezone, onSubmit, isBundleModalOpen, setBundleModalOpen } =
    useCalendarController({ openModalAndSetCalendlyUrl, coachSlug });

  const currentlySelectedDateTime = useMemo(() => (dateTime != null ? dateTime : DateTime.local()), [dateTime]);

  const {
    onMonthChange,
    availableDateTimes,
    getAvailableDateTimesFor,
    isFetching: calendarAvailabilityIsFetching,
    viewingMontDateTime,
  } = useCalendarData({
    dateTime: currentlySelectedDateTime,
    timezone,
    coachSlug,
  });

  useEffect(
    function setInitialDateTimeBaseOnAvailability() {
      if (dateTime == null && availableDateTimes.length > 0) {
        setDateTime(availableDateTimes[0]);
      }
    },
    [availableDateTimes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setDateTimeFromDate = (d: Date) => {
    const dt = DateTime.fromJSDate(d).setZone(timezone, { keepLocalTime: true });
    const firstAvailableDateTime = getAvailableDateTimesFor(dt)[0];
    setDateTime(firstAvailableDateTime);
  };

  const setDateTimeFromISO = (dateTimeISO: string) => {
    const newDateTime = DateTime.fromISO(dateTimeISO).setZone(timezone);
    if (
      Boolean(newDateTime.isValid) &&
      getAvailableDateTimesFor(newDateTime)
        .map((dt) => dt.toISO())
        .includes(newDateTime.toISO())
    ) {
      return setDateTime(newDateTime);
    }
  };

  const availableDateTimesWithNextAndPreviousMonths = useMemo(() => {
    if (calendarAvailabilityIsFetching) {
      return [];
    }
    if (availableDateTimes.length <= 0) {
      if (viewingMontDateTime.get('month') === DateTime.local().get('month')) {
        return [viewingMontDateTime.plus({ month: 1 }).endOf('month')];
      }
      return [
        viewingMontDateTime.minus({ month: 1 }).startOf('month'),
        viewingMontDateTime.plus({ month: 1 }).endOf('month'),
      ];
    }
    const endOfNextMonth = availableDateTimes[0].plus({ month: 1 }).endOf('month');
    if (availableDateTimes[0].get('month') !== DateTime.local().get('month')) {
      const beginingOfNextMonth = availableDateTimes[0].minus({ month: 1 }).startOf('month');
      return [beginingOfNextMonth, ...availableDateTimes, endOfNextMonth];
    }
    return [...availableDateTimes, endOfNextMonth];
  }, [availableDateTimes]); // eslint-disable-line react-hooks/exhaustive-deps

  const slotsEnabled =
    dateTime != null && availableDateTimes.length > 0 && viewingMontDateTime.get('month') === dateTime.get('month');

  return (
    <>
      <BundlesModal
        isOpen={isBundleModalOpen}
        onClose={() => setBundleModalOpen(false)}
        coachSlug={coachSlug}
        timezone={timezone}
        dateTime={currentlySelectedDateTime}
        isLoggedIn={isLoggedIn}
        user={user}
      />
      <form onSubmit={onSubmit}>
        <MiniSelect label="Time zone" value={timezone} onChange={(t) => setTimezone(t.target.value)}>
          {Intl.supportedValuesOf('timeZone').map((timezone) => (
            <option key={timezone} value={timezone}>
              {timezone
                .split('/')
                .map((a) => a.replaceAll('_', ' '))
                .join('/')}{' '}
              ({currentlySelectedDateTime.setZone(timezone).toFormat('ZZ')})
            </option>
          ))}
        </MiniSelect>
        <DatePicker
          isLoading={calendarAvailabilityIsFetching}
          selected={dateTime != null ? transferDateTimeToDate(dateTime.setZone(timezone)) : undefined}
          onChange={setDateTimeFromDate}
          includeDates={availableDateTimesWithNextAndPreviousMonths.map(transferDateTimeToDate)}
          onMonthChange={onMonthChange}
          className="mt-5"
        />
        <MiniSelect
          label="Session slot"
          value={dateTime?.toISO() as string}
          disabled={!slotsEnabled}
          onChange={(event) => setDateTimeFromISO(event.target.value)}
          title={slotsEnabled ? undefined : 'Select a valid day to enable time slot selection'}
          className="mt-2"
          selectClassName="!text-md"
        >
          {slotsEnabled &&
            getAvailableDateTimesFor(dateTime).map((dt) => (
              <option key={dt.toISO()} value={dt.toISO() as string}>
                {`${dt.toFormat('HH:mm')} - ${dt.plus({ hours: 1 }).toFormat('HH:mm')}`}
              </option>
            ))}
        </MiniSelect>

        {isLoggedIn && user?.type === 'Talent' ? (
          <Button
            size="large"
            className="self-start mt-6 max-xs:w-full"
            type="submit"
            disabled={dateTime == null}
            title={dateTime == null ? 'Select a date for the session to enable booking' : undefined}
          >
            Book session
          </Button>
        ) : (
          <Button
            variant="primary"
            className="self-start mt-6 max-xs:w-full"
            size="large"
            navTo={
              paths.signUp() + parseParamsToLinkSearch({ redirectUrl: signInRedirectUrl(redirectUrlFromQueryParams) })
            }
          >
            Book session
          </Button>
        )}
      </form>
    </>
  );
};

export default CalendarForm;
