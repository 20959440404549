import React, { useState, useRef, useEffect, FC, useCallback } from 'react';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import searchSelectStyles from '#components/Form/SearchSelect/SearchSelect.module.css';
import InputWrapper, { InputProps } from '../InputWrapper';
import { AutocompleteRequestFunction, AutocompleteOption } from '#root/src/types';

export interface AutocompleteInputProps extends InputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  autocompleteRequest: AutocompleteRequestFunction;
  placeholder?: string;
  disabled?: boolean;
  selectHandler?: (option: AutocompleteOption) => void;
  recordsLimit?: number;
}

const AutocompleteInput: FC<AutocompleteInputProps> = ({
  label,
  error,
  description,
  required,
  placeholder,
  className,
  value,
  onChange,
  autocompleteRequest,
  selectHandler = () => {},
  disabled = false,
  recordsLimit = 10,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const searchSelectRef = useRef<HTMLDivElement>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const debounce = useCallback((func: () => void, delay: number) => {
    if (debounceTimeout.current != null) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(func, delay);
  }, []);

  useEffect(() => {
    debounce(() => {
      void (async () => {
        if (value.length >= 3) {
          try {
            const options = await autocompleteRequest({ query: value });
            setOptions(options);
          } catch (error) {
            console.error(error);
          }
        } else {
          setOptions([]);
        }
      })();
    }, 300);
  }, [value, autocompleteRequest, debounce]);

  const handleSelect = (option: AutocompleteOption) => {
    onChange(option.name);
    setOpen(false);
    selectHandler(option);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (searchSelectRef.current != null && !searchSelectRef.current.contains(event.relatedTarget)) {
      setOpen(false);
    }
  };

  const handleFocus = () => setOpen(true);

  return (
    <InputWrapper label={label} error={error} description={description} required={required}>
      {({ id, hasError }) => (
        <div
          id={label}
          aria-labelledby={label}
          aria-disabled={disabled}
          ref={searchSelectRef}
          onBlur={handleBlur}
          className="inline-grid relative"
        >
          <input
            type="search"
            id={id}
            onFocus={handleFocus}
            className={[textBoxStyles.input, hasError ? textBoxStyles['input--error'] : '', className, 'w-full'].join(
              ' '
            )}
            autoComplete="off"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            placeholder={placeholder}
          />

          {open && options.length > 0 && (
            <div className={searchSelectStyles['option-container']}>
              {options.slice(0, recordsLimit).map((option) => (
                <div
                  key={option.id}
                  className={searchSelectStyles.option}
                  tabIndex={0}
                  onClick={() => handleSelect(option)}
                >
                  <span className={searchSelectStyles.option__label}>{option.name}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </InputWrapper>
  );
};

export default AutocompleteInput;
