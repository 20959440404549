import React, { FC, useState, useMemo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { createSuccessToast } from '#src/lib/Toasts';
import { request as apiArchiveOffer } from '#api/endpoints/employers/offers/archive';
import { KeysEnum as candidatesQueryKeys } from '../../data/InterviewingCandidatesData';
import { Icon, Modal, Button, Selectable } from '#components/Index';
import { EmployerInterviewingCandidate } from '#src/types';

interface Props {
  selectedCandidate: EmployerInterviewingCandidate;
  isOpen: boolean;
  closeModal: () => void;
}

const options = [
  {
    id: 0,
    label: 'This candidate was rejected',
  },
  {
    id: 1,
    label: 'We are still in touch with this candidate directly',
  },
  {
    id: 2,
    label: 'This candidate was hired for this role',
  },
];

const ArchiveCandidateModal: FC<Props> = ({ selectedCandidate, isOpen, closeModal }) => {
  const queryClient = useQueryClient();
  const [selectedReason, setSelectedReason] = useState<number | null>(null);

  const archiveButtonDisabled = useMemo(() => selectedReason === null, [selectedReason]);

  const onClose = useCallback(() => {
    setSelectedReason(null);
    closeModal();
  }, [closeModal]);

  const archiveCandidate = useCallback(async () => {
    if (selectedReason == null) return;

    await apiArchiveOffer({ id: selectedCandidate.offer.id, archive: { reason: selectedReason } });
    await queryClient.invalidateQueries([
      candidatesQueryKeys.employers,
      candidatesQueryKeys.talentPool,
      candidatesQueryKeys.candidates,
    ]);

    createSuccessToast({ title: 'This candidate has been archived', text: 'Thank you for your feedback!' });

    onClose();
  }, [selectedCandidate, selectedReason, queryClient, onClose]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-screen max-w-4xl gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm font-medium">
          <div className="flex flex-col gap-y-3 text-center">
            <h2 className="text-display-xs font-serif hp-black text-center">
              Archive Candidate: {selectedCandidate.profile.fullName}
            </h2>

            <p className="hp-black font-light text-md">Let us know why you’d like to archive this candidate:</p>

            <div className="flex flex-col gap-2 mt-8">
              {options.map((option) => (
                <Selectable
                  key={option.id}
                  id={`archive-${option.id}`}
                  type="radio"
                  name="current-situation"
                  size="lg"
                  value={option.id}
                  onChange={() => setSelectedReason(option.id)}
                  checked={selectedReason === option.id}
                >
                  {option.label}
                </Selectable>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-center gap-x-8 mt-8">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={archiveCandidate} disabled={archiveButtonDisabled}>
              Archive
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ArchiveCandidateModal;
