import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { EmployerAccountSettings } from '#src/types';

interface Payload {
  init: never;
  success: {
    account: EmployerAccountSettings;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/employers/accounts');
