import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '#pages/paths';
import { EmployerInterviewingCandidate } from '#src/types';
import { Icon } from '#src/components/Index';
import MenuCard from '#src/components/MenuCard/MenuCard';
import ArchiveCandidateModal from './ArchiveCandidateModal';

interface CandidateHeaderOptionsDropdownProps {
  selectedCandidate: EmployerInterviewingCandidate;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CandidateHeaderOptionsDropdown: FC<CandidateHeaderOptionsDropdownProps> = ({
  selectedCandidate,
  isOpen,
  setIsOpen,
}) => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const openArchiveModal = () => {
    setIsOpen(false);
    setIsArchiveModalOpen(true);
  };

  return (
    <>
      <ArchiveCandidateModal
        selectedCandidate={selectedCandidate}
        isOpen={isArchiveModalOpen}
        closeModal={() => setIsArchiveModalOpen(false)}
      />
      <MenuCard id="candidate-menu" open={isOpen}>
        <Link
          to={paths.employerCandidatePreview({ id: selectedCandidate.id })}
          className="px-4 py-3 text-gray-700 text-sm flex gap-3 leading-4 focus-visible-outline hover:bg-gray-50 active:bg-gray-100"
        >
          <Icon.PagesLine size={4} /> Preview candidate
        </Link>

        <button
          onClick={openArchiveModal}
          className="px-4 py-3 text-red-600 text-sm flex gap-3 leading-4 focus-visible-outline hover:bg-gray-50 active:bg-gray-100"
        >
          <Icon.DeleteBinLine size={4} /> Archive candidate
        </button>
      </MenuCard>
    </>
  );
};

export default CandidateHeaderOptionsDropdown;
