import React, { FC } from 'react';
import { filterByOpportunity } from '#src/lib/opportunity.utils';
import { Select } from '#components/Index';

interface PotentialCandidatesFiltersProps {
  opportunities: Array<{ id: number; name: string }>;
  selectedOpportunityId: number | undefined;
  setSelectedOpportunityId: (id: number | undefined) => void;
}

const PotentialCandidatesFilters: FC<PotentialCandidatesFiltersProps> = ({
  opportunities,
  selectedOpportunityId,
  setSelectedOpportunityId,
}) => {
  return (
    <div className="mb-4">
      <Select
        label="Opportunity"
        value={selectedOpportunityId}
        onChange={(event) => filterByOpportunity(event.target?.value, setSelectedOpportunityId)}
      >
        {[
          <option value={0} key="0">
            All opportunities
          </option>,
          ...opportunities.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          )),
        ]}
      </Select>
    </div>
  );
};

export default PotentialCandidatesFilters;
