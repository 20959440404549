import { useEffect, useState, useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';

import { useCalendarAvailability } from './CoachAvailabilityData';

interface UseCalendarDataProps {
  coachSlug: string;
  dateTime: DateTime;
  timezone: string;
}
export const useCalendarData = ({ coachSlug, dateTime, timezone }: UseCalendarDataProps) => {
  const getProperStartTimeDate = (dt: DateTime) => {
    if (dt.get('month') === DateTime.local({ zone: timezone }).get('month')) {
      return DateTime.local({ zone: timezone }).plus({ minute: 10 });
    } else {
      return dt.startOf('month');
    }
  };

  const [fromDateTime, setFromDateTime] = useState(() => {
    return getProperStartTimeDate(dateTime);
  });

  const { data: calendarAvailabilityData, refetch, isFetching } = useCalendarAvailability(coachSlug, fromDateTime);

  useEffect(
    function keepTimezoneSync() {
      setFromDateTime((dt) => dt.setZone(timezone));
    },
    [timezone]
  );

  useEffect(
    function getDataOnMonthChange() {
      void refetch();
    },
    [fromDateTime, timezone] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const availableDateTimes = useMemo((): DateTime[] => {
    const dateTimes: DateTime[] = [];
    if (calendarAvailabilityData?.availability == null || isFetching) {
      return dateTimes;
    }
    for (const [key, value] of Object.entries(calendarAvailabilityData.availability)) {
      value.forEach((time) => {
        const dt: DateTime = DateTime.fromFormat(`${key}${time}`, 'yyyyMMddHHmm', { zone: 'UTC' }).setZone(timezone);
        dateTimes.push(dt);
      });
    }
    return dateTimes;
  }, [calendarAvailabilityData?.availability, isFetching, timezone]);

  const getAvailableDateTimesFor = useCallback(
    (dt: DateTime) => {
      const startDateTime = dt.startOf('day');
      const endDateTime = dt.endOf('day');
      return availableDateTimes
        .filter(
          (availableDateTime) =>
            availableDateTime.toMillis() <= endDateTime.toMillis() &&
            availableDateTime.toMillis() >= startDateTime.toMillis()
        )
        .map((availableDateTime) => availableDateTime.setZone(timezone));
    },
    [timezone, availableDateTimes]
  );

  const onMonthChange = (d: Date) => {
    const dt = DateTime.fromJSDate(d).setZone(timezone);
    setFromDateTime(getProperStartTimeDate(dt));
  };

  return {
    availableDateTimes,
    getAvailableDateTimesFor,
    onMonthChange,
    isFetching,
    viewingMontDateTime: fromDateTime,
  };
};
