import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '#pages/paths';
import { Job } from '#src/types';
import { Icon, Button } from '#src/components/Index';
import HeaderOptionsDropdown from './HeaderOptionsDropdown';

interface JobContentProps {
  selectedJob: Job | null;
  setJobsListOpenOnMobile: Dispatch<SetStateAction<boolean>>;
}

const JobHeader: FC<JobContentProps> = ({ selectedJob, setJobsListOpenOnMobile }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  if (selectedJob == null) {
    return null;
  }

  return (
    <div className="grid bg-white p-6 gap-x-8 items-start lg:flex border-b border-gray-300 relative">
      <div className="bg-gray-50 rounded-full overflow-clip justify-self-start shrink-0">
        <img
          src={selectedJob.opportunity.companyLogoUrl}
          alt={selectedJob.opportunity.companyName}
          className="mix-blend-multiply max-h-[80px] m-auto sm:m-0 object-contain hidden lg:block"
        />
      </div>
      <div className="lg:hidden mb-8">
        <Button
          variant="secondary"
          aria-labelledby="Open navigation"
          onClick={() => setJobsListOpenOnMobile(true)}
          circle
        >
          <Icon.ArrowLeftLine size={5} aria-hidden="true" />
        </Button>
      </div>
      <div className="flex grow">
        <div className="grid grid-cols-1 w-full">
          <div className="flex items-center gap-x-4 lg:gap-x-0">
            <div className="bg-gray-50 rounded-full overflow-clip justify-self-start shrink-0">
              <img
                src={selectedJob.opportunity.companyLogoUrl}
                alt={selectedJob.opportunity.companyName}
                className="mix-blend-multiply max-h-[60px] m-auto sm:m-0 object-contain block md:max-h-[80px] lg:hidden"
              />
            </div>
            <div className="absolute right-20">
              <HeaderOptionsDropdown
                opportunityId={selectedJob.opportunity.id}
                isOpen={optionsOpen}
                setIsOpen={setOptionsOpen}
                canWithdrawInterest={selectedJob.canWithdrawInterest}
              />
            </div>
            <div className="flex flex-col grow">
              <div className="flex grow justify-between">
                <h3 className="font-serif text-display-xs">{selectedJob.opportunity.position}</h3>
                <div className="hidden lg:block">
                  <button
                    className="flex items-center justify-center h-8 w-8 bg-gray-50 rounded-full"
                    onClick={() => setOptionsOpen(!optionsOpen)}
                  >
                    <Icon.MoreFill size={5} />
                  </button>
                </div>
              </div>
              <p className="text-xl text-gray-500">{selectedJob.opportunity.companyName}</p>
            </div>
          </div>

          <p className="text-md font-light text-gray-500 my-2">{selectedJob.opportunity.pitch}</p>

          <div className="flex flex-col gap-x-4 gap-y-1 md:flex-row md:flex-wrap">
            <div className="flex items-center text-md text-gray-500 gap-x-2">
              <Icon.MapPinFill size={5} aria-hidden="true" className="text-green-500" />

              {selectedJob.opportunity.location}
            </div>
            <div className="flex items-center text-md text-gray-500 gap-x-2">
              <Icon.BankCardFill size={5} aria-hidden="true" className="text-green-500" />

              {selectedJob.opportunity.compensation}
            </div>
          </div>

          <Link
            to={paths.talentOpportunity({ id: selectedJob.opportunity.id })}
            className="text-md text-blue-500 font-medium mt-2"
          >
            See full job description
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JobHeader;
