import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

import * as createTeamMemberAPI from '#api/endpoints/session/createTeamMember';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';

export interface FormValues {
  token: string;
  firstName: string;
  lastName: string;
  city: string;
  country: string;
  position: string;
  phone: string;
  email: string;
  password: string;
}

const defaultFormValues: FormValues = {
  token: '',
  firstName: '',
  lastName: '',
  city: '',
  country: '',
  position: '',
  phone: '',
  email: '',
  password: '',
};

const validationSchema = object().shape({
  token: string()
    .transform((s) => (s == null ? undefined : s))
    .required('Token is required'),
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  city: string().required('City is required'),
  country: string().required('Country is required'),
  position: string().required('Position is required'),
  phone: string().optional(),
  email: string().required('Email is required'),
  password: string().trim().required('Password is required'),
});

export const useSignUpController = (defaultPropsValues?: Partial<FormValues>) => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultFormValues, ...defaultPropsValues },
  });

  const { parseErrors, baseErrors } = useParseFormErrors<createTeamMemberAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      const res = await createTeamMemberAPI.request({
        employer: { ...values },
        token: values.token,
      });
      formMethods.reset();
      window.location.href = generateOriginAppUrl({ path: res.redirectUrl });
    } catch (errors) {
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm, baseErrors };
};
