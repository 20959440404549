import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '#pages/paths';
import { EmployerOpportunityThumbnail } from '#src/types';
import { Icon } from '#components/Index';

interface EmployerOpportunityContentProps {
  opportunity: EmployerOpportunityThumbnail;
}

const EmployerOpportunityContent: FC<EmployerOpportunityContentProps> = ({ opportunity }) => {
  if (opportunity.reviewed) {
    return (
      <div>
        <Link to={paths.employerOpportunity({ id: opportunity.id })} className="underline">
          View details
        </Link>
      </div>
    );
  }

  return (
    <div className="flex gap-x-4">
      <Icon.EmptySearch size={8} className="mt-1" />
      <span className="text-sm font-light text-gray-500 max-w-96">
        Your Client Success Manager is currently reviewing this opportunity and will be in touch shortly.
      </span>
    </div>
  );
};

export default EmployerOpportunityContent;
