import React, { FC, useState, useCallback, useMemo } from 'react';
import { Errors } from '#src/types';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiEmployerCreateOpportunity } from '#api/endpoints/employers/opportunities/createOpportunity';
import { Icon, Modal, Button } from '#components/Index';
import { TextInput, FileInput } from '#components/Form/Index';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const NewOpportunityModal: FC<Props> = ({ isOpen, onClose, refetch }) => {
  const [formType, setFormType] = useState<'file' | 'url'>('file');
  const [title, setTitle] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [attachment, setAttachment] = useState<File | null>(null);
  const [errors, setErrors] = useState<Errors>({});

  const closeModal = useCallback(() => {
    setTitle('');
    setFileUrl('');
    setAttachment(null);
    setErrors({});
    onClose();
  }, [onClose]);

  const createOpportunity = useCallback(async () => {
    try {
      await apiEmployerCreateOpportunity({
        name: title,
        remoteEmployerFileUrl: fileUrl,
        file: attachment,
      });

      refetch();
      createSuccessToast({ title: 'Opportunity', text: 'Opportunity saved successfully' });
      closeModal();
    } catch (error) {
      if (typeof error === 'object' && error !== null && 'errors' in error) {
        const typedError = error as { errors: Errors };
        setErrors(typedError.errors);
      }

      createErrorToast({ title: 'Opportunity', text: 'Unable to save. Please try again.' });
    }
  }, [title, attachment, fileUrl, closeModal, refetch]);

  const formContent = useMemo(() => {
    if (formType === 'file') {
      return (
        <div>
          <FileInput
            label="File"
            setFile={setAttachment}
            file={attachment}
            description="PDF (MAX. 5MB)"
            acceptedFileTypes=".pdf"
            error={errors?.employerFile?.join(',')}
          />

          <div className="pt-2">
            <Button variant="link" className="link !font-normal !p-0" onClick={() => setFormType('url')}>
              Paste link instead
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <TextInput
          label="File URL"
          placeholder="URL"
          value={fileUrl}
          onChange={(e) => setFileUrl(e.target.value)}
          error={errors?.employerFile?.join(',')}
        />

        <div className="pt-2">
          <Button variant="link" className="link !font-normal !p-0" onClick={() => setFormType('file')}>
            Upload file
          </Button>
        </div>
      </div>
    );
  }, [formType, attachment, fileUrl, errors]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="flex flex-col bg-gray-50 w-screen max-w-4xl">
        <div className="pt-8 px-8">
          <div className="flex justify-around items-center pb-2">
            <div className="flex items-center gap-x-8 text-gray-500 text-sm">Dashboard</div>

            <button
              onClick={closeModal}
              className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
              aria-label="Close"
            >
              <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
            </button>
          </div>

          <div className="flex items-center justify-between">
            <h1 className="text-hpblack text-display-sm font-serif mb-4">Add new opportunity</h1>
          </div>
        </div>

        <div className="flex gap-y-4 flex-col px-8 pb-12">
          <TextInput
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errors?.name?.join(',')}
          />

          {formContent}
        </div>
      </div>

      <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" onClick={createOpportunity}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default NewOpportunityModal;
