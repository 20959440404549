import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { EmployerOpportunityThumbnail } from '#src/types';

interface Payload {
  init: never;
  success: {
    activeOpportunities: EmployerOpportunityThumbnail[];
    archivedOpportunities: EmployerOpportunityThumbnail[];
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/employers/opportunities');
