import React, { FC } from 'react';
import { TextInput, PhoneInputRHF, RHF } from '#components/Form/Index';
import { Button, ErrorAlert, SuccessAlert } from '#components/Index';
import { useHiringContactController } from './FormController';

interface HiringContactFormProps {
  email: string;
  alreadySent: boolean;
}

const HiringContactForm: FC<HiringContactFormProps> = ({ email, alreadySent }) => {
  const { form, submitForm, baseErrors, successMessage, closeSuccessMessage } = useHiringContactController({ email });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-y-6">
      <SuccessAlert text={successMessage} onClose={closeSuccessMessage} />

      <RHF.Select
        control={control}
        name="contactMethod"
        label="Preferred contact method"
        options={[
          { text: 'Phone', value: 'phone' },
          { text: 'Email', value: 'email' },
        ]}
      />

      <PhoneInputRHF label="Phone Number" control={form.control} name="phone" error={errors.phone?.message} />

      <TextInput label="Email" type="email" error={errors.email?.message} {...register('email')} />

      <TextInput
        label="If there are any specific questions you have, please let us know below."
        {...register('question')}
      />

      <ErrorAlert text={baseErrors} />

      <Button type="submit" className="xs:mx-auto" size="large" disabled={alreadySent}>
        Submit
      </Button>
    </form>
  );
};

export default HiringContactForm;
