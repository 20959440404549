import * as apiGetTeamMembers from '#api/endpoints/employers/team/getTeamMembers';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employer',
  teamMembers = 'teamMembers',
}

type GetApiPayload = apiGetTeamMembers.Type['success']['payload'];
export const useGetTeamMembers = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.employers, KeysEnum.teamMembers], async () => await apiGetTeamMembers.request(), {
    ...options,
  });
