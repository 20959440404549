import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';

import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import * as employerSignUpAPI from '#api/endpoints/session/signUpEmployer';

export interface FormValues extends FieldValues {
  firstName: string;
  lastName: string;
  companyName: string;
  position: string;
  phone: string;
  email: string;
}

const defaultFormValues: FormValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  position: '',
  phone: '',
  email: '',
};

const validationSchema = object().shape({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  companyName: string().required('Company is required'),
  position: string().required('Position is required'),
  phone: string().optional(),
  email: string().required('Email is required'),
});

export const useSignUpController = () => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });
  const { parseErrors, baseErrors } = useParseFormErrors<employerSignUpAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await employerSignUpAPI.request({
        prospect: {
          ...values,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    } catch (errors) {
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm, baseErrors };
};
