import React, { FC, useMemo } from 'react';
import { alternativeFormattedDateFromISO } from '#root/src/lib/dateParsers';
import ReceivedMessage from '#pages/talentPages/activity/elements/content/history/ReceivedMessage';
import SentMessage from '#pages/talentPages/activity/elements/content/history/SentMessage';
import DefaultHistoryEntry from '#pages/talentPages/activity/elements/content/history/DefaultHistoryEntry';

interface CandidateHistoryItemProps {
  isLast: boolean;
  date: string;
  type: string;
  message: string;
  avatarUrl: string;
}

const CandidateHistoryItem: FC<CandidateHistoryItemProps> = ({ type, date, message, isLast, avatarUrl }) => {
  const parsedDate = useMemo(() => alternativeFormattedDateFromISO(date), [date]);

  switch (type) {
    case 'talent_message':
      return (
        <ReceivedMessage
          date={parsedDate}
          message={message}
          isLast={isLast}
          avatarUrl={avatarUrl}
          alternateStyleAvatar
        />
      );
    case 'employer_message':
      return <SentMessage date={parsedDate} message={message} isLast={isLast} />;
    case 'archived':
      return (
        <DefaultHistoryEntry
          date={parsedDate}
          message={
            <>
              <span className="font-normal">You archived this candidate:</span> {message}
            </>
          }
          isLast={isLast}
        />
      );
    default:
      return <DefaultHistoryEntry date={parsedDate} message={message} isLast={isLast} />;
  }
};

export default CandidateHistoryItem;
