import { mapValues } from 'lodash-es';
import { compile } from 'path-to-regexp';

interface RouteParams {
  id?: number;
  storyId?: number;
  slug?: string;
  secretId?: string;
}

export const ROUTES = {
  root: '/',
  candidates: '/candidates',
  employer: '/employer',
  employers: '/employers',
  contactUs: '/contact-us',
  about: '/about',
  supportForCandidate: '/support',
  supportForEmployer: '/support-employer',
  signIn: '/sign-in',
  signUp: '/sign-up',
  signUpEmployer: '/sign-up-employer',
  resetPassword: '/reset-password',
  activateAccount: '/activate-account',
  updatePassword: '/password/edit',
  signUpEmployerTeamMember: '/employer/accounts/new',
  jobsOnboarding: '/jobs-onboarding',
  story: '/story/:slug',
  stories: '/stories',
  blogPost: '/blog/:slug',
  blog: '/blog',
  cookies: '/cookies',
  terms: '/terms',
  privacy: '/privacy',
  jobs: '/jobs',
  interviewPrep: '/interview',
  coaches: '/coaches',
  coach: '/coach/:slug',
  talentOpportunities: '/talent/opportunities',
  talentOpportunity: '/talent/opportunities/:id',
  opportunityOverview: '/opportunities/overview/:secretId',
  talentActivity: '/talent/offers',
  talentProfile: '/talent/profile',
  talentPreferences: '/talent/profile/preferences',
  talentSettings: '/talent/settings',
  talentProfileEmployerView: '/talent/profile/view-as-employer',
  talentHiring: '/talent/hiring',
  employerCandidatePreview: '/employer/candidates/:id',
  employerDashboard: '/employer/dashboard',
  employerPotentialCandidates: '/employer/talent-pool/potential',
  employerInterviews: '/employer/talent-pool/interviews',
  employerReferrals: '/employer/referrals',
  employerTeamSettings: '/employer/team-members',
  employerAccountSettings: '/employer/accounts/edit',
  employerOpportunity: '/employer/opportunities/:id',
} as const;

// URLs of pages that are not part of the new frontend
export const OLD_FRONTEND_ROUTES = {
  talentStartOnboarding: '/talent/profile/start_onboarding',
  adminDashboard: '/superadmin',
} as const;

export const paths = mapValues({ ...ROUTES, ...OLD_FRONTEND_ROUTES }, (v) => compile<RouteParams>(v));

export function parseParamsToLinkSearch(
  params: Record<string, number | string | boolean | Array<number | string | boolean>>
) {
  const paramsWithStringValues: Record<string, string> = Object.create(null);

  Object.entries(params).forEach(([key, param]) => {
    if (param === '') return;
    paramsWithStringValues[key] = param.toString();
  });

  return '?' + new URLSearchParams(paramsWithStringValues).toString();
}

export function signInRedirectUrl(redirectUrlFromQueryParams: string | null) {
  const currentPath = window.location.pathname;
  const isCoachPage = currentPath.includes('/coach/');

  if (redirectUrlFromQueryParams !== null && redirectUrlFromQueryParams !== '') return redirectUrlFromQueryParams;
  if (isCoachPage || currentPath === paths.coaches()) return currentPath.substring(1);
  if (currentPath === paths.interviewPrep()) return paths.coaches().substring(1);

  return '';
}
