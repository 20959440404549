import React, { FC, useState, useEffect } from 'react';
import { OpportunityThumbnail } from '#src/types';
import { useGetHandpickedOpportunities } from './data/HandpickedOpportunitiesData';
import { Button, Icon } from '#components/Index';
import HandpickedCard from './elements/HandpickedCard';
import { OpportunitiesList } from './elements/OpportunitiesList';

const Handpicked: FC = () => {
  const [opportunities, setOpportunities] = useState<OpportunityThumbnail[]>([]);

  const { data: handpickedOpportunities, isLoading, refetch } = useGetHandpickedOpportunities();

  useEffect(() => {
    if (handpickedOpportunities == null) return;

    setOpportunities(handpickedOpportunities.opportunities);
  }, [handpickedOpportunities]);

  const NoMatchingOpportunities: FC = () => {
    return (
      <div>
        <div className="bg-white py-8 flex justify-center rounded-sm">
          <div className="w-1/2 text-center">
            <Icon.EmptyBookmarked size={12} />
            <h3 className="text-display-sm font-serif text-hpblack mb-4">
              We don’t have any hand-picked opportunities for you right now
            </h3>
            <p className="text-sm mb-4">
              If your account is new or you recently updated your preferences, we could still be searching for your
              perfect opportunity. You can check back later but we’ll also email you when we think we’ve found the right
              fit for you.
            </p>
            <p className="text-sm mb-8">
              If it’s been a while and we’re not finding you many roles, you could try updating your preferences and
              extending the areas you’re interested in.
            </p>
            <Button variant="secondary">
              Try updating my preferences <Icon.ArrowRightLine size={4} aria-hidden="true" />
            </Button>
          </div>
        </div>

        <div className="flex flex-col space-y-8 mt-8">
          <div className="bg-white h-32 w-full opacity-60"></div>
          <div className="bg-white h-32 w-full opacity-45"></div>
          <div className="bg-white h-32 w-full opacity-30"></div>
          <div className="bg-white h-32 w-full opacity-20"></div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-row gap-x-8 px-4">
      <div className="basis-1/4 hidden lg:block">
        <HandpickedCard />
      </div>
      <OpportunitiesList
        isLoading={isLoading}
        opportunities={opportunities}
        emptyState={<NoMatchingOpportunities />}
        refetchOpportunities={refetch}
      />
    </div>
  );
};

export default Handpicked;
