import React, { FC } from 'react';

import { CoachBundles, Icon, Modal } from '#components/Index';
import * as apiGetBundleLink from '#api/endpoints/checkouts/buyBundle';
import { useUserDetails } from '#root/src/AppData';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const BundlesModal: FC<Props> = ({ isOpen, onClose }) => {
  const { isLoggedIn, user } = useUserDetails();

  const onClick =
    isLoggedIn && user?.type === 'Talent'
      ? async (sessionPackageId: number) => {
          const payload = await apiGetBundleLink.request({ sessionPackageId });
          window.location.href = payload.redirectUrl;
        }
      : undefined;

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-8 grid w-screen max-w-4xl gap-12">
        <div className="flex gap-8">
          <h2 className="text-display-md font-serif">Book a bundle of sessions and save</h2>

          <button
            onClick={onClose}
            className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
            aria-label="Close"
          >
            <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
          </button>
        </div>

        <CoachBundles onClick={onClick} />
      </div>
    </Modal>
  );
};

export default BundlesModal;
