/* eslint-disable react/display-name */
import { useForwardRef } from '#root/src/lib/useForwardRef';
import React, { useEffect, forwardRef, useRef, ChangeEvent } from 'react';

import TextInput, { Props } from '../TextInput/TextInput';
import './LocationInput.css';

const id = 'google-autocomplete';

const LocationInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const combinedRef = useForwardRef<HTMLInputElement>(ref, inputRef);

  async function initialize() {
    if (import.meta.env.VITE_GOOGLE_MAPS_KEY == null) return;

    const { Loader } = await import('@googlemaps/js-api-loader');
    const { current } = combinedRef;

    if (current == null) return;

    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
      version: 'weekly',
      libraries: ['places'],
      language: 'en',
    });

    const options = { types: ['(regions)'] };

    void (async () => {
      await loader.load();
      const autocomplete = new google.maps.places.Autocomplete(current, options);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (place.formatted_address != null && props.onChange != null) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          props.onChange({
            target: { value: place.formatted_address },
          } as ChangeEvent<HTMLInputElement>);
        }
      });
    })();
  }

  useEffect(() => {
    void (async () => {
      await initialize();
    })();
  }, [ref, inputRef, combinedRef, props]); // eslint-disable-line react-hooks/exhaustive-deps

  return <TextInput {...props} ref={combinedRef} id={id} />;
});

export default LocationInput;
