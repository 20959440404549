import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface StatsCardProps {
  icon: React.ReactNode;
  count: number;
  description: string;
  accessible: boolean;
  href: string;
}

const StatsCard: FC<StatsCardProps> = ({ icon, count, description, accessible, href }) => {
  const statistics = () => {
    return (
      <>
        <span className="font-serif text-display-md group-hover:text-green-800">{count}</span>
        <span className="font-light group-hover:text-green-800">{description}</span>
      </>
    );
  };

  if (!accessible) {
    return (
      <div className="flex flex-col gap-y-2 items-center p-8">
        <div className="grayscale">{icon}</div>
        {statistics()}
      </div>
    );
  }

  return (
    <Link className="flex flex-col gap-y-2 items-center p-8 group cursor-pointer" to={href}>
      <div>{icon}</div>
      {statistics()}
    </Link>
  );
};

export default StatsCard;
