import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import * as apiCheckCreditsBalance from '#api/endpoints/checkouts/checkCreditsBalance';
import * as apiGenerateCalendlyLink from '#root/src/api/endpoints/coaches/generateScheduleLink';

const MY_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const DATETIME_URL_FORMAT = 'yyyyMMdd-HHmm';
export const DATETIME_CALENDLY_FORMAT = "yyyy-MM-dd'T'HH:mm";

const useCalendarController = ({
  coachSlug,
  openModalAndSetCalendlyUrl,
}: {
  coachSlug: string;
  openModalAndSetCalendlyUrl: (calendlyUrl: string) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleSubmit } = useForm();
  const [isBundleModalOpen, setBundleModalOpen] = useState(false);

  const [timezone, setTimezoneState] = useState(() => {
    const timezone = searchParams.get('timezone');
    return timezone != null ? timezone : MY_TIMEZONE;
  });

  // dateTime is in timezone selected by user
  const [dateTime, setDateTime] = useState<DateTime | undefined>(() => {
    const searchDate = searchParams.get('date');

    if (searchDate != null && /^\d{4}\d{2}\d{2}|\d{2}\d{2}$/.test(searchDate)) {
      const urDateTimeUTC = DateTime.fromFormat(searchDate, DATETIME_URL_FORMAT, { zone: 'UTC' }).setZone(timezone);
      const isInFuture = (): boolean => urDateTimeUTC.diff(DateTime.utc(), 'minutes').minutes > 0;

      if (Boolean(urDateTimeUTC.isValid) && isInFuture()) {
        return urDateTimeUTC;
      }
    }
  });

  useEffect(
    function syncPropsToUrlParams() {
      if (dateTime != null) {
        const hash = { timezone, date: dateTime.toUTC().toFormat(DATETIME_URL_FORMAT) };
        setSearchParams(hash, { replace: true });
      }
    },
    [dateTime, timezone, setSearchParams]
  );

  useEffect(function openModalOnParameter() {
    const isOpenModal = searchParams.get('open_modal');
    if (isOpenModal != null) {
      void requestForCalendlyAndOpenModal();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setTimezone = (t: string) => {
    if (dateTime != null) {
      setDateTime((d) => (d as DateTime).setZone(t));
    }
    setTimezoneState(t);
  };

  const requestForCalendlyAndOpenModal = async () => {
    if (dateTime == null) {
      return undefined;
    }

    const payload = await apiGenerateCalendlyLink.request({
      slug: coachSlug,
      timezone,
      selectedTime: dateTime.toUTC().toISO() as string,
    });
    const url = new URL(`${payload.schedulingLink}/${dateTime.toUTC().toFormat(DATETIME_CALENDLY_FORMAT)}Z`);
    url.searchParams.set('timezone', timezone);
    openModalAndSetCalendlyUrl(url.toString());
  };

  const submit = async () => {
    const payload = await apiCheckCreditsBalance.request({ slug: coachSlug });
    if (payload.credits > 0) {
      void requestForCalendlyAndOpenModal();
    } else {
      setBundleModalOpen(true);
    }
  };

  return {
    dateTime,
    setDateTime,
    timezone,
    setTimezone,
    onSubmit: handleSubmit(submit),
    isBundleModalOpen,
    setBundleModalOpen,
  };
};

export default useCalendarController;
