import React, { FC, useMemo } from 'react';
import { relativeDateFromISO } from '#src/lib/dateParsers';
import { TalentSponsor, Opportunity } from '#src/types';
import { OpportunityReplyModalType } from '#pages/talentPages/opportunities/modals/OpportunityRepliesModalManager';
import { useUserDetails } from '#src/AppData';
import { Icon } from '#src/components/Index';
import AwaitingResponse from './content/AwaitingResponse';
import Interested from './content/Interested';

interface TalentSponsorCardProps {
  talentSponsor: TalentSponsor;
  opportunity: Opportunity;
  openModal: (modal: OpportunityReplyModalType) => void;
}

const TalentSponsorCard: FC<TalentSponsorCardProps> = ({ talentSponsor, opportunity, openModal }) => {
  const { user } = useUserDetails();

  const parsedDate = useMemo(() => relativeDateFromISO(opportunity.candidacy.state.date), [opportunity.candidacy]);

  const content = useMemo(() => {
    switch (opportunity.candidacy.state.type) {
      case 'interested':
        return (
          <Interested
            user={user}
            parsedDate={parsedDate}
            message={opportunity.candidacy.state.message}
            opportunityId={opportunity.id}
          />
        );
      case 'handpicked':
      case 'awaiting_response':
        return (
          <AwaitingResponse
            user={user}
            openModal={openModal}
            isHandpicked={opportunity.candidacy.state.type === 'handpicked'}
            withRequirements={opportunity.requirements.length > 0}
            message={opportunity.candidacy.state.message}
          />
        );
      default:
        return null;
    }
  }, [opportunity, parsedDate, user, openModal]);

  return (
    <div className="rounded-lg overflow-clip shadow-md-darkish bg-white lg:sticky lg:top-10">
      <div className="px-6 pt-6">
        <div className="flex items-center gap-x-4 mb-4">
          <img src={talentSponsor.avatarUrl} className="w-14 h-14 rounded-full object-contain" />
          <div>
            <h5 className="font-semibold text-lg">{talentSponsor.fullName}</h5>
            <span className="font-light text-gray-500 font">Hiperpool</span>
          </div>
        </div>

        <div className="flex flex-col text-sm text-gray-500 mb-4 gap-y-1">
          {talentSponsor.phoneNumber.length > 0 && (
            <div className="flex gap-x-2 items-center">
              <Icon.MapPin size={5} className="text-green-500" />
              {talentSponsor.phoneNumber}
            </div>
          )}

          {talentSponsor.email.length > 0 && (
            <div className="flex gap-x-2 items-center">
              <Icon.MailFill size={5} className="text-green-500" />
              {talentSponsor.email}
            </div>
          )}
        </div>
      </div>

      {content}
    </div>
  );
};

export default TalentSponsorCard;
