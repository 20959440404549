import { Errors } from '#root/src/types';
import { createAction, PATCH, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    id: number;
    header: {
      position: string;
      pitch: string;
      jobLocalisation: string;
      compensation: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'position' | 'pitch' | 'jobLocalisation' | 'compensation'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(PATCH, `/employers/opportunities/${params.id}/header`)(params);
