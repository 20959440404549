import React, { FC } from 'react';
import { EmployerAccountSettings } from '#src/types';
import { TextInput, PhoneInputRHF } from '#components/Form/Index';
import { useEmployerAccountSettingsController } from './FormController';
import { Button, FormContentGroup, SuccessAlert } from '#components/Index';

interface AccountSettingsFormProps {
  account: EmployerAccountSettings;
}

const AccountSettingsForm: FC<AccountSettingsFormProps> = ({ account }) => {
  const { form, submitForm, successMessage, closeSuccessMessage } = useEmployerAccountSettingsController({ account });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <form onSubmit={onSubmit} className="flex divide-y-2 divide-gray-50 flex-col">
      <SuccessAlert text={successMessage} onClose={() => closeSuccessMessage()} />

      <FormContentGroup title="Your Details">
        <div className="flex flex-col gap-y-6 px-16">
          <TextInput label="First name" {...register('firstName')} error={errors.firstName?.message} />
          <TextInput label="Last name" {...register('lastName')} error={errors.lastName?.message} />
          <TextInput label="City" {...register('city')} error={errors.city?.message} />
          <TextInput label="Country" {...register('country')} error={errors.country?.message} />
          <TextInput label="Job title" {...register('position')} error={errors.position?.message} />
          <PhoneInputRHF label="Phone" control={form.control} name="phone" error={errors.phone?.message} />
          <TextInput label="Email" type="email" {...register('email')} error={errors.email?.message} />
        </div>
      </FormContentGroup>

      <FormContentGroup title="Password">
        <div className="flex flex-col gap-y-6 px-16">
          <TextInput
            label="Current Password"
            type="password"
            {...register('currentPassword')}
            error={errors.currentPassword?.message}
          />

          <TextInput
            label="New password"
            description="Min. 8 characters"
            type="password"
            {...register('password')}
            error={errors.password?.message}
          />

          <TextInput
            label="Confirm new password"
            type="password"
            {...register('passwordConfirmation')}
            error={errors.passwordConfirmation?.message}
          />
        </div>
      </FormContentGroup>

      <div className="flex justify-end pt-6">
        <Button type="submit">Save changes</Button>
      </div>
    </form>
  );
};

export default AccountSettingsForm;
