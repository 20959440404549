import { useEffect } from 'react';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import * as createEducationAPI from '#root/src/api/endpoints/talents/profile/educations/createEducation';
import * as updateEducationAPI from '#root/src/api/endpoints/talents/profile/educations/updateEducation';

export interface FormValues extends FieldValues {
  college: string;
  degree: string;
  field: string;
  graduationYear: string;
}

const defaultFormValues = (college: string, degree: string, field: string, graduationYear: string): FormValues => ({
  college,
  degree,
  field,
  graduationYear,
});

const validationSchema = object().shape({
  college: string().required('University is required'),
  degree: string().required('Degree is required'),
  field: string().required('Field of study is required'),
  graduationYear: string().required('Year of graduation is required'),
});

interface ControllerProps {
  id?: number;
  college?: string;
  degree?: string;
  field?: string;
  graduationYear?: string;
  successCallback: () => void;
}

export const useEducationController = ({
  id = 0,
  college = '',
  degree = '',
  field = '',
  graduationYear = '',
  successCallback,
}: ControllerProps) => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(college, degree, field, graduationYear),
  });

  const { reset } = formMethods;

  useEffect(() => {
    // Reset the form values when the educationEntry props changes (for example after opening the modal for existing education)
    reset(defaultFormValues(college, degree, field, graduationYear));
  }, [college, degree, field, graduationYear, reset]);

  const { parseErrors } = useParseFormErrors<createEducationAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const updateRequest = async () => {
      return await updateEducationAPI.request({
        id,
        education: {
          ...values,
        },
      });
    };

    const createRequest = async () => {
      return await createEducationAPI.request({
        education: {
          ...values,
        },
      });
    };

    try {
      await (id !== 0 ? updateRequest() : createRequest());
      createSuccessToast({ title: 'Education' });
      successCallback();
    } catch (errors) {
      createErrorToast({ title: 'Education' });
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm };
};
