import React, { FC, useMemo } from 'react';
import { relativeDateFromISO } from '#src/lib/dateParsers';
import { OpportunityThumbnail } from '#src/types';
import { paths, parseParamsToLinkSearch } from '#pages/paths';
import { Button, Icon } from '#components/Index';

interface OpportunityFooterContentProps {
  opportunity: OpportunityThumbnail;
  handleNotInterested: (opportunity: OpportunityThumbnail) => void;
}

const OpportunityFooterContent: FC<OpportunityFooterContentProps> = ({ opportunity, handleNotInterested }) => {
  const { type, message, date, talentSponsorAvatarUrl } = opportunity.candidacy.state;

  const parsedDate = useMemo(() => relativeDateFromISO(date), [date]);

  const awaitingResponseActions = (
    <>
      <Button size="small" variant="secondary" onClick={() => handleNotInterested(opportunity)}>
        <Icon.X size={4} aria-hidden="true" className="shrink-0 left-5 max-2xs:absolute" />{' '}
        <span className="text-center">Not interested</span>
      </Button>
      <Button size="small" to={paths.talentOpportunity({ id: opportunity.id })}>
        <span className="text-center">Find out more</span>{' '}
        <Icon.ArrowRightLine size={4} aria-hidden="true" className="shrink-0 right-5 max-2xs:absolute" />
      </Button>
    </>
  );

  switch (type) {
    case 'handpicked':
      return (
        <div className="bg-green-100 flex flex-wrap gap-y-2 gap-x-4 flex-col justify-between sm:flex-row lg:items-center">
          <div className="flex items-center text-gray-500 text-xs font-light gap-x-2 self-center px-4 pt-2 md:pt-0">
            <img src={talentSponsorAvatarUrl} className="w-9 h-9 rounded-full object-contain" />

            <div className="flex flex-col">
              <span className="text-sm font-bold text-hpblack">{message}</span>
            </div>
          </div>

          <div className="bg-green-100 flex gap-y-2 gap-x-4 items-center justify-around p-3 md:justify-normal">
            {awaitingResponseActions}
          </div>
        </div>
      );
    case 'archived':
      return (
        <div className="flex gap-y-2 gap-x-4 justify-between bg-gray-200 lg:items-center">
          <div className="flex items-center text-gray-500 text-xs font-light gap-x-2 px-4 py-2">
            <Icon.ClockCircle size={9} />
            <div className="flex flex-col">
              <span className="text-sm font-bold text-hpblack">{message}</span>
              <span className="font-light">{parsedDate}</span>
            </div>
          </div>
        </div>
      );
    case 'not_interested':
      return (
        <div className="flex gap-y-2 gap-x-4 justify-between bg-gray-200 lg:items-center">
          <div className="flex items-center text-gray-500 text-xs font-light gap-x-2 px-4 py-2">
            <Icon.DislikeCircle size={9} />
            <div className="flex flex-col">
              <span className="text-sm font-bold text-hpblack max-w-[14rem] md:max-w-none">{message}</span>
              <span className="font-light">{parsedDate}</span>
            </div>
          </div>
        </div>
      );
    case 'interested':
      return (
        <div className="flex gap-x-4 bg-green-200 flex-col justify-between sm:flex-row lg:items-center">
          <div className="flex items-center text-gray-500 text-xs font-light gap-x-2 px-4 py-2">
            <Icon.FeaturedCircle size={9} />
            <div className="flex flex-col">
              <span className="text-sm font-bold text-hpblack">{message}</span>
              <span className="font-light">{parsedDate}</span>
            </div>
          </div>
          <div className="flex items-center justify-around p-3 md:justify-normal">
            <Button
              size="small"
              to={paths.talentActivity() + parseParamsToLinkSearch({ opportunityId: opportunity.id })}
              className="relative"
            >
              See latest activity <Icon.ArrowRightLine size={4} aria-hidden="true" />
            </Button>
          </div>
        </div>
      );
    case 'awaiting_response':
      return (
        <div className="flex flex-wrap gap-y-4 gap-x-4 flex-col justify-end sm:flex-row sm:bg-gray-50 lg:items-center">
          <div className="bg-gray-50 flex gap-y-2 gap-x-4 items-center justify-around p-3 md:justify-normal">
            {awaitingResponseActions}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default OpportunityFooterContent;
