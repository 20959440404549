import React, { FC } from 'react';
import { Icon } from '#components/Index';

interface DashboardEmptyStateProps {
  section: 'active' | 'archived';
}

const DashboardEmptyState: FC<DashboardEmptyStateProps> = ({ section }) => {
  const title = section === 'active' ? 'There are no active opportunities' : 'There are no archived opportunities';

  const description =
    section === 'active' ? (
      <div>
        <p className="text-sm mb-4">
          This is where you’ll find an overview of your active and archived opportunities. For each role, you’ll see a
          tally of candidates hand-selected for your review, as well as those already interviewing.
        </p>

        <p className="text-sm">
          Start by adding a new opportunity, or reach out to your Hiperpool contact so we can set one up for you.
        </p>
      </div>
    ) : (
      <p className="text-sm">
        Once you archive an opportunity due to various reasons, you will be able to view it here - both its description
        and the list of all candidates that were ever assigned to it.
      </p>
    );

  return (
    <div>
      <div className="bg-white py-12 flex justify-center rounded-md">
        <div className="w-2/3 text-center">
          <Icon.EmptyBookmarked size={12} />
          <h3 className="text-display-sm font-serif text-hpblack my-4">{title}</h3>
          {description}
        </div>
      </div>

      {section === 'archived' && (
        <div className="flex flex-col space-y-8 mt-8">
          <div className="bg-white h-32 w-full opacity-60 rounded-md"></div>
          <div className="bg-white h-32 w-full opacity-45 rounded-md"></div>
          <div className="bg-white h-32 w-full opacity-30 rounded-md"></div>
        </div>
      )}
    </div>
  );
};

export default DashboardEmptyState;
