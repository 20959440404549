import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    teamMember: {
      email: string;
      firstName: string;
      lastName: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'email' | 'firstName' | 'lastName'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/employers/team_members');
