import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

import * as updatePasswordAPI from '#api/endpoints/session/updatePassword';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

export interface FormValues {
  password: string;
  resetPasswordToken: string;
}

const defaultFormValues: FormValues = {
  password: '',
  resetPasswordToken: '',
};

const validationSchema = object().shape({
  password: string().trim().required('Password is required'),
  resetPasswordToken: string()
    .transform((s) => (s == null ? undefined : s))
    .required('Reset password token is required'),
});

export const useUpdatePasswordController = (defaultPropsValues?: Partial<FormValues>) => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultFormValues, ...defaultPropsValues },
  });

  const { parseErrors, baseErrors } = useParseFormErrors<updatePasswordAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await updatePasswordAPI.request({
        talent: values,
      });
      formMethods.reset();
    } catch (errors) {
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm, baseErrors };
};
