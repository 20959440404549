import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { EmployerInterviewingCandidate } from '#src/types';

interface Payload {
  init: {
    opportunityId?: number;
  };
  success: {
    candidates: EmployerInterviewingCandidate[];
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, '/employers/talent_pool/interviewing')(params);
