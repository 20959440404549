import React, { FC, Dispatch, SetStateAction } from 'react';
import { EmployerInterviewingCandidate } from '#src/types';
import { Spinner } from '#components/Index';
import EmptyCandidatesContent from './EmptyCandidatesContent';
import CandidateContent from './CandidateContent';

interface InterviewContentProps {
  selectedCandidate: EmployerInterviewingCandidate | null;
  setCandidatesListOpenOnMobile: Dispatch<SetStateAction<boolean>>;
  selectedFilter: string;
  isLoading: boolean;
}

const InterviewContent: FC<InterviewContentProps> = ({
  isLoading,
  selectedCandidate,
  setCandidatesListOpenOnMobile,
  selectedFilter,
}) => {
  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (selectedCandidate != null) {
    return (
      <CandidateContent
        selectedCandidate={selectedCandidate}
        setCandidatesListOpenOnMobile={setCandidatesListOpenOnMobile}
      />
    );
  }

  return <EmptyCandidatesContent currentFilter={selectedFilter} />;
};

export default InterviewContent;
