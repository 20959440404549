import React, { FC } from 'react';

import { CoachBundles } from '#components/Index';
import * as apiGetBundleLink from '#api/endpoints/checkouts/buyBundle';
import { useUserDetails } from '#root/src/AppData';

const Bundles: FC = () => {
  const { isLoggedIn, user } = useUserDetails();

  const onClick =
    isLoggedIn && user?.type === 'Talent'
      ? async (sessionPackageId: number) => {
          const payload = await apiGetBundleLink.request({ sessionPackageId });
          window.location.href = payload.redirectUrl;
        }
      : undefined;

  return (
    <div className="my-20 px-variable-sm grid gap-12">
      <h2 className="text-display-md font-serif text-center">Book a bundle of sessions and save</h2>
      <CoachBundles onClick={onClick} />
    </div>
  );
};

export default Bundles;
