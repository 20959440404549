import React, { FC } from 'react';
import DOMPurify from 'dompurify';

interface ReceivedMessageProps {
  date: string;
  message: string;
  isLast: boolean;
  avatarUrl: string;
  alternateStyleAvatar?: boolean;
}

const ReceivedMessage: FC<ReceivedMessageProps> = ({
  date,
  message,
  isLast,
  avatarUrl,
  alternateStyleAvatar = false,
}) => {
  return (
    <li>
      <div className="relative pb-4">
        {!isLast && <span className="absolute left-2.5 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true"></span>}
        <div className="relative flex space-x-3">
          <div>
            {alternateStyleAvatar ? (
              <div className="min-h-10 max-h-10 min-w-10 bg-white border max-w-10 -mt-2 -ml-2.5 rounded-full overflow-hidden justify-center items-center">
                <img src={avatarUrl} className="min-w-full min-h-full" />
              </div>
            ) : (
              <div className="h-10 w-10 -mt-2 -ml-2 bg-white flex items-center justify-center rounded-full">
                <img src={avatarUrl} className="w-6 h-6 mix-blend-multiply" />
              </div>
            )}
          </div>
          <div className="flex flex-col text-md -mt-1">
            <div className="flex flex-col max-w-lg">
              <div
                className="bg-[#CBEDE7] text-hpblack font-light rounded-md p-4 rich-text rich-text-lists flex flex-col"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
              />
              <span className="text-sm font-light text-gray-500">{date}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ReceivedMessage;
