import React, { FC, useMemo } from 'react';
import { Job } from '#src/types';
import JobsListItem from './JobsListItem';

interface JobsListProps {
  jobs?: Job[];
  setSelectedJob?: (job: Job) => void;
}

const JobsList: FC<JobsListProps> = ({ jobs, setSelectedJob = () => {} }) => {
  const sortedJobs = useMemo(() => {
    if (jobs == null) return [];

    return jobs.sort((a, b) => {
      const aDate = a.history?.lastActivityAt != null ? new Date(a.history.lastActivityAt) : new Date(0);
      const bDate = b.history?.lastActivityAt != null ? new Date(b.history.lastActivityAt) : new Date(0);

      return bDate.getTime() - aDate.getTime();
    });
  }, [jobs]);

  return (
    <div className="flex flex-col gap-y-2 mt-4 overflow-y-auto lg:max-h-[calc(88dvh)]">
      {sortedJobs.map((job) => (
        <JobsListItem key={job.opportunity.id} job={job} setSelectedJob={() => setSelectedJob(job)} />
      ))}
    </div>
  );
};

export default JobsList;
