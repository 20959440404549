import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths, parseParamsToLinkSearch } from '#pages/paths';
import { EmployerOpportunityThumbnail } from '#src/types';
import { ReactComponent as People } from '#assets/homepage/jobs/people.svg';
import { ReactComponent as Checklist } from '#assets/homepage/jobs/checklist.svg';
import EmployerOpportunityContent from './EmployerOpportunityContent';
import StatsCard from './StatsCard';

interface EmployerOpportunityCardProps {
  opportunity: EmployerOpportunityThumbnail;
}

const EmployerOpportunityCard: FC<EmployerOpportunityCardProps> = ({ opportunity }) => {
  const navigate = useNavigate();

  const fullyAccessible = opportunity.reviewed && !opportunity.archived;

  const navigateToPotentialCandidates = () => {
    if (!fullyAccessible) return;

    navigate(paths.employerPotentialCandidates() + parseParamsToLinkSearch({ opportunityId: opportunity.id }));
  };

  return (
    <div className="grid rounded-lg overflow-clip shadow-md-darkish bg-gray-50 transition outline outline-3 -outline-offset-3 outline-green-200/0 relative hover:shadow-md-darker">
      <div className="flex justify-between">
        <div className="flex flex-1 flex-col justify-between p-8">
          <div
            className={`flex flex-col flex-1 ${fullyAccessible ? 'cursor-pointer' : ''}`}
            onClick={navigateToPotentialCandidates}
          >
            <h3 className="font-serif text-display-xs">{opportunity.name}</h3>
          </div>

          <EmployerOpportunityContent opportunity={opportunity} />
        </div>

        <div className="grid grid-cols-2 divide-x-2 bg-white">
          <StatsCard
            icon={<People />}
            count={opportunity.pendingCandidatesCount}
            description="Pending Review"
            accessible={fullyAccessible}
            href={paths.employerPotentialCandidates() + parseParamsToLinkSearch({ opportunityId: opportunity.id })}
          />

          <StatsCard
            icon={<Checklist />}
            count={opportunity.interviewingCandidatesCount}
            description="Interviewing"
            accessible={fullyAccessible}
            href={paths.employerInterviews() + parseParamsToLinkSearch({ opportunityId: opportunity.id })}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployerOpportunityCard;
