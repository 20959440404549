import { useState } from 'react';
import { string, object, ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { EmployerAccountSettings } from '#src/types';
import * as updateAccountSettingsAPI from '#api/endpoints/employers/settings/updateAccount';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

export interface FormValues extends FieldValues {
  firstName: string;
  lastName: string;
  city: string;
  country: string;
  position: string;
  phone: string;
  email: string;
  currentPassword?: string;
  password?: string | null;
  passwordConfirmation?: string;
}

const defaultFormValues = (account: EmployerAccountSettings): FormValues => {
  return {
    firstName: account.firstName,
    lastName: account.lastName,
    city: account.city,
    country: account.country,
    position: account.position,
    phone: account.phone,
    email: account.email,
    currentPassword: '',
    password: null,
    passwordConfirmation: '',
  };
};

const validationSchema = object().shape({
  firstName: string().trim().required('First name is required'),
  lastName: string().trim().required('Last name is required'),
  city: string().trim().required('City is required'),
  country: string().trim().required('Country is required'),
  position: string().trim().required('Position is required'),
  phone: string().trim().required('Phone is required'),
  email: string().trim().required('Email is required'),
  currentPassword: string().when('password', {
    is: (password: string | undefined) => password != null,
    then: string().trim().required('Current password is required'),
    otherwise: string().optional(),
  }),
  password: string()
    .trim()
    .nullable(true)
    .optional()
    .test('len', 'Password must be at least 8 characters', (value) => value == null || value.length >= 8),
  passwordConfirmation: string().when('password', {
    is: (password: string | undefined) => password != null,
    then: string()
      .trim()
      .required('Confirmation is required')
      .oneOf([ref('password'), null], 'Passwords must match'),
    otherwise: string().optional(),
  }),
});

interface ControllerProps {
  account: EmployerAccountSettings;
}

export const useEmployerAccountSettingsController = ({ account }: ControllerProps) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const closeSuccessMessage = () => setSuccessMessage(null);

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(account),
  });

  const { parseErrors } = useParseFormErrors<updateAccountSettingsAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await updateAccountSettingsAPI.request({
        employer: {
          ...values,
        },
      });
      setSuccessMessage('Account settings updated successfully');
    } catch (errors) {
      parseErrors(errors);
      setSuccessMessage(null);
    }
  };

  return { form: formMethods, submitForm, successMessage, closeSuccessMessage };
};
