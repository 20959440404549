import { Errors } from '#root/src/types';
import { createAction, PATCH, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    id: number;
    opportunity: {
      section: 'why_interesting_body' | 'about_company_body' | 'about_role_body';
      content: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'content' | 'section'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(PATCH, `/employers/opportunities/${params.id}/content_section`)(params);
