import * as apiGetEmployerAccountSettings from '#api/endpoints/employers/settings/getAccountDetails';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employer',
  accountSettings = 'accountSettings',
}

type GetApiPayload = apiGetEmployerAccountSettings.Type['success']['payload'];
export const useGetEmployerAccountSettings = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.employers, KeysEnum.accountSettings],
    async () => await apiGetEmployerAccountSettings.request(),
    {
      ...options,
    }
  );
