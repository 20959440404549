import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { NameResource } from '#src/types';

interface Payload {
  init: never;
  success: {
    opportunities: NameResource[];
    potentialCandidatesCount: number;
    interviewsCount: number;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/employers/talent_pool/data');
