import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '#pages/paths';
import { EmployerInterviewingCandidate } from '#src/types';
import { Icon, Button } from '#src/components/Index';
import CandidateHeaderOptionsDropdown from './CandidateHeaderOptionsDropdown';

interface JobContentProps {
  selectedCandidate: EmployerInterviewingCandidate | null;
  setCandidatesListOpenOnMobile: Dispatch<SetStateAction<boolean>>;
}

const CandidateHeader: FC<JobContentProps> = ({ selectedCandidate, setCandidatesListOpenOnMobile }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  if (selectedCandidate == null) {
    return null;
  }

  return (
    <div className="grid bg-white p-6 gap-x-8 items-start lg:flex border-b border-gray-300 relative">
      <div className="min-w-[80px] min-h-[80px] max-w-[80px] max-h-[80px] rounded-full overflow-hidden justify-center items-center hidden lg:block">
        <img
          src={selectedCandidate.profile.avatarUrl}
          alt={selectedCandidate.profile.fullName}
          className="min-w-full min-h-full"
        />
      </div>
      <div className="lg:hidden mb-8">
        <Button
          variant="secondary"
          aria-labelledby="Open navigation"
          onClick={() => setCandidatesListOpenOnMobile(true)}
          circle
        >
          <Icon.ArrowLeftLine size={5} aria-hidden="true" />
        </Button>
      </div>
      <div className="flex grow">
        <div className="grid grid-cols-1 w-full">
          <div className="flex items-center gap-x-4 lg:gap-x-0">
            <div className="min-w-[60px] min-h-[60px] max-w-[60px] max-h-[60px] rounded-full overflow-hidden justify-center items-center lg:hidden">
              <img
                src={selectedCandidate.profile.avatarUrl}
                alt={selectedCandidate.profile.fullName}
                className="min-w-full min-h-full"
              />
            </div>

            <div className="absolute right-20">
              <CandidateHeaderOptionsDropdown
                selectedCandidate={selectedCandidate}
                isOpen={optionsOpen}
                setIsOpen={setOptionsOpen}
              />
            </div>

            <div className="flex flex-col grow">
              <div className="flex grow justify-between">
                <h3 className="font-serif text-display-xs">{selectedCandidate.profile.fullName}</h3>
                {!selectedCandidate.offer.archived && (
                  <div className="hidden lg:block">
                    <button
                      className="flex items-center justify-center h-8 w-8 bg-gray-50 rounded-full"
                      onClick={() => setOptionsOpen(!optionsOpen)}
                    >
                      <Icon.MoreFill size={5} />
                    </button>
                  </div>
                )}
              </div>
              <p className="text-md text-gray-500">{selectedCandidate.profile.position}</p>
            </div>
          </div>

          <div className="flex flex-col gap-x-4 gap-y-1 my-1 md:flex-row md:flex-wrap">
            <div className="flex items-center text-md text-gray-500 gap-x-2">
              <Icon.MapPinFill size={5} aria-hidden="true" className="text-green-500" />

              {selectedCandidate.profile.location}
            </div>
          </div>

          <Link
            to={paths.employerCandidatePreview({ id: selectedCandidate.id })}
            className="text-md text-blue-500 font-medium mt-2"
          >
            See full profile description
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CandidateHeader;
