import React, { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Spinner } from '#components/Index';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

interface DatePickerProps extends ReactDatePickerProps {
  isLoading?: boolean;
}

const DatePicker: FC<DatePickerProps> = ({ className, isLoading, ...props }) => {
  const datePickerContainerClasses = isLoading === true ? 'opacity-50 pointer-events-none' : '';

  return (
    <div className="flex flex-col relative">
      {isLoading === true && <Spinner className="absolute z-10 inset-0" />}
      <div className={datePickerContainerClasses}>
        <ReactDatePicker inline calendarClassName={className} {...props} useWeekdaysShort />
      </div>
    </div>
  );
};

export default DatePicker;
