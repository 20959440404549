import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

import * as resetPasswordAPI from '#api/endpoints/session/resetPassword';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

export interface FormValues {
  email: string;
}

const defaultFormValues: FormValues = {
  email: '',
};

const validationSchema = object().shape({
  email: string().required('Email is required'),
});

export const useSignInController = () => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const { parseErrors, baseErrors } = useParseFormErrors<resetPasswordAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await resetPasswordAPI.request({
        talent: values,
      });
      formMethods.reset();
    } catch (errors) {
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm, baseErrors };
};
