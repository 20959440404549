import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import InputWrapper from '../InputWrapper';

export interface Props {
  name: string;
  control: Control<any>;
  label: string;
  description?: string;
  error?: string;
  required?: boolean;
  placeholder?: string;
}

const PhoneInputRHF: FC<Props> = ({ name: propsName, control, label, description, error, required, placeholder }) => {
  const basePhoneInputClasses = 'flex flex-1 !py-2 !rounded-lg !border-gray-300 text-gray-900 font-normal';

  return (
    <InputWrapper label={label} description={description} error={error} required={required}>
      {({ id, hasError }) => (
        <Controller
          name={propsName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div
              ref={(element) => {
                if (element != null) {
                  const inputElement = element.querySelector('input');
                  if (inputElement != null) {
                    inputElement.id = id;
                  }
                }
              }}
            >
              <ReactPhoneInput
                placeholder={placeholder}
                value={value}
                containerClass="flex"
                inputClass={[basePhoneInputClasses, hasError ? textBoxStyles[`input--error`] : ''].join(' ')}
                onChange={onChange}
                country="gb"
              />
            </div>
          )}
        />
      )}
    </InputWrapper>
  );
};

export default PhoneInputRHF;
