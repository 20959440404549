import { string, object } from 'yup';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import * as editHeaderAPI from '#api/endpoints/employers/opportunities/editHeader';
import { KeysEnum as opportunityQueryKeys } from '../../data/EmployerOpportunityData';

export interface FormValues extends FieldValues {
  position: string;
  pitch: string;
  jobLocalisation: string;
  compensation: string;
}

const defaultFormValues = (
  position: string,
  pitch: string,
  jobLocalisation: string,
  compensation: string
): FormValues => ({
  position,
  pitch,
  jobLocalisation,
  compensation,
});

const validationSchema = object().shape({
  position: string().required('Opportunity title is required'),
  pitch: string().required('Pitch is required'),
  jobLocalisation: string().required('Location is required'),
  compensation: string().required('Compensation is required'),
});

interface ControllerProps {
  id: number;
  position: string;
  pitch: string;
  jobLocalisation: string;
  compensation: string;
  closeModal: () => void;
}

export const useOpportunityHeaderController = ({
  id,
  position,
  pitch,
  jobLocalisation,
  compensation,
  closeModal,
}: ControllerProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(position, pitch, jobLocalisation, compensation),
  });

  const { parseErrors } = useParseFormErrors<editHeaderAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await editHeaderAPI.request({
        id,
        header: values,
      });
      closeModal();
      createSuccessToast({ title: 'Opportunity header' });
      void queryClient.invalidateQueries([
        opportunityQueryKeys.employers,
        opportunityQueryKeys.opportunities,
        opportunityQueryKeys.show,
        id,
      ]);
    } catch (errors) {
      createErrorToast({ title: 'Opportunity header' });
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm };
};
