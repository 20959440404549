import React, { FC } from 'react';
import { Icon } from '#components/Index';

interface FileProgressBarProps {
  file: File | null;
}

const FileProgressBar: FC<FileProgressBarProps> = ({ file }) => {
  if (file == null) return null;

  return (
    <div className="flex gap-x-4 bg-white rounded-lg border-2 border-blue-500 mt-8 p-4">
      <div className="bg-blue-200 rounded-full w-9 h-9 flex justify-center items-center">
        <Icon.File size={5} aria-hidden="true" />
      </div>
      <div className="grow">
        <p className="font-medium">{file.name}</p>
        <span className="text-gray-500">{(file.size / (1024 * 1024)).toFixed(2)} MB</span>
        <div className="flex flex-row gap-x-2">
          <div className="grow h-2 bg-blue-500 rounded-md mt-2"></div>
          <span>100%</span>
        </div>
      </div>
      <div>
        <Icon.CheckBoxCircleFill size={5} className="text-blue-600" />
      </div>
    </div>
  );
};

export default FileProgressBar;
