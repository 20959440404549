import React, { FC } from 'react';
import { Button } from '#src/components/Index';
import styles from '#pages/talentPages/opportunities/index/elements/OpportunityNavbar.module.css';

interface DashboardNavbarProps {
  dashboardSection: 'active' | 'archived';
  setDashboardSection: (section: 'active' | 'archived') => void;
  activeCount: number;
  archivedCount: number;
}

const DashboardNavbar: FC<DashboardNavbarProps> = ({
  dashboardSection,
  setDashboardSection,
  activeCount,
  archivedCount,
}) => {
  const buttonClassNames = [styles.navLink];

  if (dashboardSection === 'active') buttonClassNames.push(styles.navLinkActive);

  return (
    <div className="flex justify-between border-b-2 border-gray-200 mb-4">
      <div className="flex gap-x-4 justify-center w-full md:gap-x-8 md:justify-normal">
        <Button variant="link" className={buttonClassNames.join(' ')} onClick={() => setDashboardSection('active')}>
          Active
          <span className="flex items-center justify-center text-green-900 bg-green-200 min-w-[1.25rem] w-auto h-5 rounded-sm px-1">
            {activeCount}
          </span>
        </Button>
        <Button
          variant="link"
          className={`${styles.navLink} ${dashboardSection === 'archived' ? styles.navLinkActive : ''}`}
          onClick={() => setDashboardSection('archived')}
        >
          Archived
          <span className="flex items-center justify-center text-green-900 bg-green-200 min-w-[1.25rem] w-auto h-5 rounded-sm px-1">
            {archivedCount}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default DashboardNavbar;
