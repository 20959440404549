import * as apiGetPotentialCandidates from '#api/endpoints/employers/talentPool/getPotentialCandidates';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employers',
  talentPool = 'talentPool',
  potential = 'potential',
}

type GetApiPayload = apiGetPotentialCandidates.Type['success']['payload'];
export const useGetPotentialCandidates = (opportunityId?: number, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.employers, KeysEnum.talentPool, KeysEnum.potential, opportunityId].filter((item) => item != null),
    async () => await apiGetPotentialCandidates.request({ opportunityId }),
    {
      ...options,
    }
  );
