import React, { FC } from 'react';
import { Icon, Button } from '#src/components/Index';

interface LockedMessagingProps {
  message: string;
  handleButtonClick?: () => void;
  buttonText?: string;
}

const LockedMessaging: FC<LockedMessagingProps> = ({ message, buttonText = '', handleButtonClick = null }) => {
  return (
    <div className="p-8">
      <div className="p-4 flex items-center justify-between rounded-md bg-[#F1F1F1]">
        <div className="flex items-center gap-x-4">
          <Icon.AlertCircle size={5} className="text-green-900" />
          <p className="text-gray-500 font-light">{message}</p>
        </div>

        {handleButtonClick != null && buttonText !== '' && <Button onClick={handleButtonClick}>{buttonText}</Button>}
      </div>
    </div>
  );
};

export default LockedMessaging;
