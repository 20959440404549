import React, { FC, ReactNode } from 'react';
import { TalentSponsor } from '#src/types';
import { Icon } from '#src/components/Index';

interface TalentSponsorCardProps {
  talentSponsor: TalentSponsor;
  children?: ReactNode;
}

const TalentSponsorCard: FC<TalentSponsorCardProps> = ({ talentSponsor, children }) => {
  return (
    <div className="rounded-lg overflow-clip bg-gray-50 p-8">
      <div className="flex items-center gap-x-4 mb-4">
        <img src={talentSponsor.avatarUrl} className="w-14 h-14 rounded-full object-contain" />
        <div>
          <h5 className="font-semibold text-lg">{talentSponsor.fullName}</h5>
          <span className="font-light text-gray-500 font">Hiperpool</span>
        </div>
      </div>

      <div className="flex flex-col text-sm text-gray-500 mb-4 gap-y-1">
        {talentSponsor.phoneNumber.length > 0 && (
          <div className="flex gap-x-2 items-center">
            <Icon.MapPin size={5} className="text-green-500" />
            {talentSponsor.phoneNumber}
          </div>
        )}

        {talentSponsor.email.length > 0 && (
          <div className="flex gap-x-2 items-center">
            <Icon.MailFill size={5} className="text-green-500" />
            {talentSponsor.email}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default TalentSponsorCard;
