import React, { FC, useState } from 'react';
import MenuCard from './MenuCard';
import * as logoutAPI from '#api/endpoints/session/logout';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';
import menuStyles from './MenuCard.module.css';
import { Icon } from '../Index';
import { User } from '#root/src/types';
import BundlesModal from './BundlesModal';
import { paths } from '#pages/paths';
import { Link } from 'react-router-dom';

interface Props {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  user: User | null;
}

const ProfileMenuCard: FC<Props> = ({ id, open, setOpen, user }) => {
  const [isBundleModalOpen, setBundleModalOpen] = useState(false);

  if (user == null) return null;

  const logout = async () => {
    const res = await logoutAPI.request({});
    window.location.href = generateOriginAppUrl({ path: res.redirectUrl });
  };

  const ProfileMenuCardLink: FC<{ to: string; children: React.ReactNode; className?: string }> = ({
    to,
    children,
    className = '',
  }) => (
    <Link to={to} onClick={() => setOpen(false)} className={`${menuStyles.item} ${menuStyles.link} ${className}`}>
      {children}
    </Link>
  );

  return (
    <MenuCard id={id} open={open}>
      {user.type === 'Talent' && (
        <ProfileMenuCardLink to={paths.talentProfile()} className={'[&>*]:translate-y-px'}>
          <Icon.UserLine size={4} aria-hidden="true" />
          <span>View profile</span>
        </ProfileMenuCardLink>
      )}

      {user.type === 'Employer' && (
        <>
          <ProfileMenuCardLink to={paths.employerTeamSettings()}>
            <Icon.UserLine size={4} aria-hidden="true" />
            <span>Team Settings</span>
          </ProfileMenuCardLink>
          <ProfileMenuCardLink to={paths.employerAccountSettings()}>
            <Icon.Settings size={4} aria-hidden="true" />
            <span>Account Settings</span>
          </ProfileMenuCardLink>
        </>
      )}

      {user.type === 'Talent' && (
        <>
          <ProfileMenuCardLink to={paths.talentSettings()}>
            <Icon.Settings size={4} aria-hidden="true" />
            <span>Account Settings</span>
          </ProfileMenuCardLink>
          <hr></hr>
          <div className={menuStyles.item}>
            <Icon.UserFrame size={4} aria-hidden="true" />
            <div>
              <div>
                Coaching credits: <span>{user.coachingSessionsCredits}</span>
              </div>
              <button
                onClick={() => setBundleModalOpen(true)}
                className={`${menuStyles['inline-link']} ${menuStyles['inline-link-has-icon']} text-xs font-medium`}
              >
                <span>Buy more credits</span>
                <Icon.ArrowRightLine size={3} aria-hidden="true" className="ml-px transition" />
              </button>
            </div>
          </div>
          <BundlesModal isOpen={isBundleModalOpen} onClose={() => setBundleModalOpen(false)} />
        </>
      )}
      {user.type === 'SuperAdmin' && (
        <>
          <a
            href={generateOriginAppUrl({ path: paths.adminDashboard() })}
            className={`${menuStyles.item} ${menuStyles.link}`}
          >
            <Icon.Settings size={4} aria-hidden="true" />
            <span>Dashboard</span>
          </a>
        </>
      )}

      <hr></hr>
      <button onClick={logout} className={`${menuStyles.item} ${menuStyles.link}`}>
        <Icon.LogoutBox size={4} aria-hidden="true" />
        <span>Sign out</span>
      </button>
    </MenuCard>
  );
};

export default ProfileMenuCard;
