import { createFormDataAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#root/src/types';

interface Payload {
  init: {
    name: string;
    remoteEmployerFileUrl: string;
    file: File | null;
  };
  success: never;
  fail: {
    errors: Errors<'name' | 'employerFile'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createFormDataAction<Payload>(POST, '/employers/opportunities')(params);
