import React, { FC } from 'react';
import { Button } from '#components/Index';
import { FileInput } from '#components/Form/Index';

interface Props {
  cv: File | null;
  setCv: (cv: File) => void;
  alternateAction: () => void;
}

export const CvForm: FC<Props> = ({ cv, setCv, alternateAction }) => {
  return (
    <div className="">
      <div>
        <h2 className="text-display-md font-serif text-hpblack text-center">Upload your CV</h2>
      </div>
      <FileInput setFile={setCv} file={cv} acceptedFileTypes=".pdf" description="PDF (MAX. 5MB)" label="" />

      <div className="pt-4">
        <Button variant="link" className="link !font-normal !p-0" onClick={alternateAction}>
          My CV isn’t ready right now
        </Button>
      </div>
    </div>
  );
};
