import React, { FC, useMemo, Dispatch, SetStateAction } from 'react';
import { relativeDateFromISO } from '#src/lib/dateParsers';
import { EmployerInterviewingCandidate } from '#src/types';

interface JobsListItemProps {
  candidate: EmployerInterviewingCandidate;
  setSelectedCandidate: Dispatch<SetStateAction<EmployerInterviewingCandidate | null>>;
}

const JobsListItem: FC<JobsListItemProps> = ({ candidate, setSelectedCandidate }) => {
  const parsedDate = useMemo(
    () => relativeDateFromISO(candidate.history.lastActivityAt),
    [candidate.history.lastActivityAt]
  );

  return (
    <div
      className="flex gap-x-4 items-start py-1 px-4 cursor-pointer hover:bg-gray-50"
      onClick={() => setSelectedCandidate(candidate)}
    >
      <div className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] rounded-full overflow-hidden justify-center items-center">
        <img src={candidate.profile.avatarUrl} alt={candidate.profile.fullName} className="min-w-full min-h-full" />
      </div>
      <div className="flex flex-col justify-center grow">
        <div className="flex gap-x-2 items-center justify-between">
          <h4 className="text-lg text-hpblack font-serif"> {candidate.profile.fullName}</h4>
          {candidate.history.withNotification && <span className="w-3 h-3 rounded-full bg-green-500"></span>}
        </div>

        <span className="text-md font-light text-gray-500">RE: {candidate.opportunity.name}</span>
        <div className="flex justify-end items-center">
          <span className="text-gray-400 text-sm">{parsedDate}</span>
        </div>
      </div>
    </div>
  );
};

export default JobsListItem;
