export const filterByOpportunity = (
  opportunityId: string,
  setSelectedOpportunityId: (id: number | undefined) => void
) => {
  const parsedOpportunityId = parseInt(opportunityId);

  if (isNaN(parsedOpportunityId)) return;

  if (parsedOpportunityId === 0) {
    setSelectedOpportunityId(undefined);
    return;
  }

  setSelectedOpportunityId(parsedOpportunityId);
};

export const opportunityIdFromParams = (searchParams: URLSearchParams) => {
  const opportunityId = searchParams.get('opportunityId');

  if (opportunityId != null) return parseInt(opportunityId);

  return undefined;
};
