import React, { FC, useCallback, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { EmployerInterviewingCandidate } from '#src/types';
import { KeysEnum as candidatesQueryKeys } from '../../data/InterviewingCandidatesData';
import { request as apiOfferReply } from '#api/endpoints/employers/offers/offerReply';
import { Button } from '#src/components/Index';

interface OfferReplyProps {
  candidate: EmployerInterviewingCandidate;
}

const OfferReply: FC<OfferReplyProps> = ({ candidate }) => {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState('');

  const handleReply = useCallback(async () => {
    await apiOfferReply({ id: candidate.offer.id, reply: { content: message } });
    await queryClient.invalidateQueries([
      candidatesQueryKeys.employers,
      candidatesQueryKeys.talentPool,
      candidatesQueryKeys.candidates,
    ]);

    setMessage('');
  }, [candidate, message, queryClient]);

  const sendMessageButtonLocked = useMemo(() => message === '', [message]);

  return (
    <div className="flex flex-col gap-y-4 px-8">
      <textarea
        className="w-full h-20 p-4 bg-white text-gray-500 border border-gray-300 rounded-md"
        placeholder="Enter your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="flex justify-end pb-4">
        <Button size="small" disabled={sendMessageButtonLocked} onClick={handleReply}>
          Send message
        </Button>
      </div>
    </div>
  );
};

export default OfferReply;
