import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useGetEmployerOpportunityData } from './data/EmployerOpportunityData';
import { OpportunityCard, TruncatedContent, TeamMemberCard, Spinner, Button, Icon } from '#src/components/Index';
import ArchiveOpportunityModal from './employerModals/ArchiveOpportunityModal';
import EditHeaderModal from './employerModals/editHeader/EditHeaderModal';
import EditOpportunityContentSectionModal from './employerModals/editContentSection/EditOpportunityContentSectionModal';
import PotentialColleaguesModal from './employerModals/editPotentialColleagues/PotentialColleaguesModal';
import Header from '#src/pages/talentPages/opportunities/show/elements/Header';
import NotFound from '#pages/NotFound';

const EmployerOpportunity: FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetEmployerOpportunityData(Number(id));

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [headerModalOpen, setHeaderModalOpen] = useState(false);
  const [interestingModalOpen, setInterestingModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [potentialColleaguesModalOpen, setPotentialColleaguesModalOpen] = useState(false);

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  return (
    <div className="min-h-full">
      <div className="bg-hpblack">
        <Header />
      </div>

      <div className="grow flex flex-col max-w-6xl mx-auto -mt-24 pb-32">
        <OpportunityCard
          opportunity={data.opportunity}
          openArchiveModal={() => setArchiveModalOpen(true)}
          openEditModal={() => setHeaderModalOpen(true)}
          stateless
        />

        <div className="xl:px-16 divide-y-2">
          <div className="py-8">
            <div className="flex justify-between">
              <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.whyInterestingTitle}</h2>
              <div>
                <Button variant="secondary" size="small" onClick={() => setInterestingModalOpen(true)}>
                  <Icon.EditPencil size={3} />
                  Edit section
                </Button>
              </div>
            </div>

            <div
              className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.whyInterestingBody) }}
            />
          </div>

          <div className="py-8">
            <div className="flex justify-between">
              <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.aboutCompanyTitle}</h2>
              <div>
                <Button variant="secondary" size="small" onClick={() => setCompanyModalOpen(true)}>
                  <Icon.EditPencil size={3} />
                  Edit section
                </Button>
              </div>
            </div>

            <TruncatedContent gradientClass="from-gray-100" initiallyExpanded>
              <div
                className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.aboutCompanyBody) }}
              />
            </TruncatedContent>
          </div>

          <div className="py-8">
            <div className="flex justify-between">
              <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.aboutRoleTitle}</h2>
              <div>
                <Button variant="secondary" size="small" onClick={() => setRoleModalOpen(true)}>
                  <Icon.EditPencil size={3} />
                  Edit section
                </Button>
              </div>
            </div>

            <TruncatedContent gradientClass="from-gray-100" initiallyExpanded>
              <div
                className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.aboutRoleBody) }}
              />
            </TruncatedContent>
          </div>

          <div className="py-8">
            <div className="flex justify-between">
              <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.teamMembersTitle}</h2>
              <div>
                <Button variant="secondary" size="small" onClick={() => setPotentialColleaguesModalOpen(true)}>
                  <Icon.EditPencil size={3} />
                  Edit section
                </Button>
              </div>
            </div>

            <div className="grid lg:grid-cols-2 gap-8 mt-2">
              {data.opportunity.teamMembers.map((colleague) => (
                <TeamMemberCard {...colleague} key={colleague.name} />
              ))}
            </div>

            <p className="text-gray-500 font-light mt-8">
              Note: These are examples of people you might work with. Please refrain from contacting them for
              recruitment purposes, as it might negatively affect your candidacy. If you have any questions, the best
              way to learn more is by asking a member of our team.
            </p>
          </div>
        </div>
      </div>

      <ArchiveOpportunityModal
        opportunityId={data.opportunity.id}
        isOpen={archiveModalOpen}
        closeModal={() => setArchiveModalOpen(false)}
      />

      <EditHeaderModal
        opportunity={data.opportunity}
        isOpen={headerModalOpen}
        closeModal={() => setHeaderModalOpen(false)}
      />

      <EditOpportunityContentSectionModal
        opportunityId={data.opportunity.id}
        initialContent={data.opportunity.whyInterestingBody}
        section="why_interesting_body"
        sectionTitle={data.opportunity.whyInterestingTitle}
        isOpen={interestingModalOpen}
        closeModal={() => setInterestingModalOpen(false)}
      />

      <EditOpportunityContentSectionModal
        opportunityId={data.opportunity.id}
        initialContent={data.opportunity.aboutCompanyBody}
        section="about_company_body"
        sectionTitle={data.opportunity.aboutCompanyTitle}
        isOpen={companyModalOpen}
        closeModal={() => setCompanyModalOpen(false)}
      />

      <EditOpportunityContentSectionModal
        opportunityId={data.opportunity.id}
        initialContent={data.opportunity.aboutRoleBody}
        section="about_role_body"
        sectionTitle={data.opportunity.aboutRoleTitle}
        isOpen={roleModalOpen}
        closeModal={() => setRoleModalOpen(false)}
      />

      <PotentialColleaguesModal
        opportunity={data.opportunity}
        isOpen={potentialColleaguesModalOpen}
        closeModal={() => setPotentialColleaguesModalOpen(false)}
      />
    </div>
  );
};

export default EmployerOpportunity;
