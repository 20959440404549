import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { KeysEnum as ProfileDetailsKeysEnum } from '#src/pages/talentPages/profileAndPreferences/profile/data/ProfileDetails';
import * as updateSummaryAPI from '#api/endpoints/talents/profile/updateSummary';

const queryKeys = [ProfileDetailsKeysEnum.talents, ProfileDetailsKeysEnum.profile, ProfileDetailsKeysEnum.details];

export interface FormValues extends FieldValues {
  summary: string;
}

const defaultFormValues: FormValues = {
  summary: '',
};

const validationSchema = object().shape({
  summary: string().trim().required('Summary is required'),
});

interface ControllerProps {
  closeEditMode: () => void;
}

export const useSummaryController = ({ closeEditMode }: ControllerProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const { parseErrors } = useParseFormErrors<updateSummaryAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await updateSummaryAPI.request({
        summary: values.summary,
      });
      closeEditMode();
      createSuccessToast({ title: 'Summary' });
      void queryClient.invalidateQueries(queryKeys);
    } catch (errors) {
      createErrorToast({ title: 'Summary' });
      parseErrors(errors);
    }
  };

  return { form: formMethods, submitForm };
};
