import React, { FC, useState } from 'react';
import { useGetEmployerOpportunitiesData } from './data/EmployerOpportunitiesData';
import DashboardNavbar from './elements/DashboardNavbar';
import NewOpportunityModal from './elements/NewOpportunityModal';
import EmployerOpportunityCard from './elements/EmployerOpportunityCard/EmployerOpportunityCard';
import DashboardEmptyState from './elements/DashboardEmptyState';
import { Spinner, Button } from '#components/Index';

const Dashboard: FC = () => {
  const [newOpportunityModalOpen, setNewOpportunityModalOpen] = useState(false);
  const [dashboardSection, setDashboardSection] = useState<'active' | 'archived'>('active');

  const { data: opportunitiesData, isLoading, refetch } = useGetEmployerOpportunitiesData();

  const activeOpportunities = opportunitiesData?.activeOpportunities;
  const archivedOpportunities = opportunitiesData?.archivedOpportunities;

  const selectedOpportunities = dashboardSection === 'active' ? activeOpportunities : archivedOpportunities;

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  if (activeOpportunities == null || archivedOpportunities == null || selectedOpportunities == null) {
    return null;
  }

  return (
    <>
      <div className="grow flex flex-col max-w-5xl mx-auto lg:py-12">
        <DashboardNavbar
          dashboardSection={dashboardSection}
          setDashboardSection={setDashboardSection}
          activeCount={activeOpportunities.length}
          archivedCount={archivedOpportunities.length}
        />

        <div className="flex flex-col gap-y-8">
          {selectedOpportunities.length === 0 && <DashboardEmptyState section={dashboardSection} />}

          {selectedOpportunities.map((opportunity) => (
            <EmployerOpportunityCard key={opportunity.id} opportunity={opportunity} />
          ))}
        </div>

        {dashboardSection === 'active' && (
          <div className="flex flex-col grow mt-8">
            <Button size="large" onClick={() => setNewOpportunityModalOpen(true)}>
              + Add new opportunity
            </Button>
          </div>
        )}
      </div>

      <NewOpportunityModal
        isOpen={newOpportunityModalOpen}
        onClose={() => setNewOpportunityModalOpen(false)}
        refetch={refetch}
      />
    </>
  );
};

export default Dashboard;
