import * as apiGetEmployerOpportunities from '#api/endpoints/employers/opportunities/getOpportunities';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employer',
  opportunities = 'opportunities',
}

type GetApiPayload = apiGetEmployerOpportunities.Type['success']['payload'];
export const useGetEmployerOpportunitiesData = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.employers, KeysEnum.opportunities],
    async () => await apiGetEmployerOpportunities.request(),
    {
      ...options,
    }
  );
