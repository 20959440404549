/* eslint-disable react/display-name */
import React, { ChangeEvent, forwardRef } from 'react';
import FileProgressBar from './FileProgressBar';
import InputWrapper, { InputProps } from '../InputWrapper';
import { Icon } from '#components/Index';

interface FileInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    InputProps {
  setFile: (cv: File) => void;
  file: File | null;
  acceptedFileTypes?: string;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ error, description, label, required, placeholder, file, setFile, acceptedFileTypes, ...rest }, ref) => {
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];

      if (selectedFile == null) return;
      setFile(selectedFile);
    };

    const labelClasses = (hasError: boolean) => {
      const base = 'flex flex-col items-center justify-center w-full border rounded-lg cursor-pointer bg-white py-6';

      return hasError ? `${base} border-red-500` : `${base} border-gray-200`;
    };

    return (
      <InputWrapper label={label} error={error} required={required}>
        {({ id, hasError }) => (
          <>
            <div className="flex items-center justify-center">
              <label className={labelClasses(hasError)}>
                <Icon.ImportCircle size={12} className="mx-auto" aria-hidden="true" />
                <p className="text-sm text-gray-500 dark:text-gray-500">
                  <span className="text-blue-500">Click to upload</span>
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-500">{description}</p>

                <input
                  id={id}
                  ref={ref}
                  type="file"
                  accept={acceptedFileTypes}
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>

            <FileProgressBar file={file} />
          </>
        )}
      </InputWrapper>
    );
  }
);

export default FileInput;
