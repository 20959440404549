import React, { FC } from 'react';
import { EmployerInterviewingCandidate } from '#src/types';
import CandidateListItem from './CandidateListItem';

interface CandidatesListProps {
  candidates: EmployerInterviewingCandidate[];
  displayCandidateDetails?: (candidates: EmployerInterviewingCandidate) => void;
}

const CandidatesList: FC<CandidatesListProps> = ({ candidates, displayCandidateDetails = () => {} }) => {
  return (
    <div className="flex flex-col gap-y-2 mt-4 overflow-y-auto lg:max-h-[calc(88dvh)]">
      {candidates.map((candidate) => (
        <CandidateListItem
          key={candidate.id}
          candidate={candidate}
          setSelectedCandidate={() => displayCandidateDetails(candidate)}
        />
      ))}
    </div>
  );
};

export default CandidatesList;
