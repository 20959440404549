import { Errors } from '#root/src/types';
import { createFormDataAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    opportunityId: number;
    teamMemberId: number;
    avatar: File;
  };
  success: never;
  fail: {
    errors: Errors<'avatar'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createFormDataAction<Payload>(
    POST,
    `/employers/opportunities/${params.opportunityId}/team_member_avatar`
  )(params);
