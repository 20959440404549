import React, { FC, useState } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useUserDetails } from '#src/AppData';
import { request as apiRemoveTeamMember } from '#api/endpoints/employers/team/removeTeamMember';
import { useGetTeamMembers } from './data/TeamMembersData';
import NewTeamMemberModal from './elements/NewTeamMemberModal';
import { FormContentGroup, Button, Spinner, Icon } from '#components/Index';

const TeamSettings: FC = () => {
  const { user } = useUserDetails();
  const { data: teamMembersData, isLoading, refetch } = useGetTeamMembers();

  const [newTeamMemberModalOpen, setNewTeamMemberModalOpen] = useState(false);

  const handleRemoveTeamMember = async (id: number) => {
    try {
      await apiRemoveTeamMember({ id });
      createSuccessToast({ title: 'Team Settings', text: 'Team member has been successfully removed.' });
      void refetch();
    } catch (error) {
      createErrorToast({ title: 'Team Settings', text: 'Failed to remove team member.' });
    }
  };

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  if (teamMembersData == null) {
    return null;
  }

  return (
    <>
      <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6 px-8 2xl:px-0">
        <h1 className="hp-black font-serif text-display-xs mb-4">Your Team on Hiperpool</h1>
        <div className="w-full p-8 bg-white rounded-lg shadow-md-dark mb-12">
          <FormContentGroup
            title="Team Members"
            description="Manage your hiring team here. Simply add a team member and they’ll receive an email to create their login
          details."
          >
            <div className="flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-hpblack sm:pl-0"
                        >
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-hpblack">
                          Email
                        </th>

                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 text-sm text-gray-500">
                      {teamMembersData.teamMembers.map((member) => (
                        <tr key={member.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">{member.fullName}</td>
                          <td className="whitespace-nowrap px-3 py-4 ">{member.email}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 sm:pr-0">
                            {member.email !== user?.email && (
                              <Button
                                variant="link"
                                size="small"
                                circle={true}
                                title="Remove"
                                onClick={async () => await handleRemoveTeamMember(member.id)}
                              >
                                <Icon.X size={5} className="text-red-600" />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Button variant="secondary" onClick={() => setNewTeamMemberModalOpen(true)}>
                + Add Team Member
              </Button>
            </div>
          </FormContentGroup>
        </div>
      </div>

      <NewTeamMemberModal
        isOpen={newTeamMemberModalOpen}
        onClose={() => setNewTeamMemberModalOpen(false)}
        refetch={refetch}
      />
    </>
  );
};

export default TeamSettings;
