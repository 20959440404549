import React, { FC, Dispatch, SetStateAction } from 'react';
import { filterByOpportunity } from '#src/lib/opportunity.utils';
import { Select } from '#root/src/components/Index';
import FilterBadge from '#components/FilterBadge/FilterBadge';
import { NameResource } from '#root/src/types';

const interviewFilterOptions = [
  {
    label: 'Interviewing',
    value: 'interviewing',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

interface CandidateFiltersProps {
  opportunities?: NameResource[];
  selectedFilter: string;
  setSelectedFilter: Dispatch<SetStateAction<string>>;
  opportunityId: number | undefined;
  setOpportunityId: Dispatch<SetStateAction<number | undefined>>;
}

const CandidateFilters: FC<CandidateFiltersProps> = ({
  opportunities,
  selectedFilter,
  setSelectedFilter,
  opportunityId,
  setOpportunityId,
}) => {
  return (
    <div className="p-4 lg:mt-4">
      <div className="flex flex-wrap gap-2">
        {interviewFilterOptions.map((filter) => (
          <FilterBadge
            key={filter.value}
            handleChange={() => setSelectedFilter(filter.value)}
            checked={selectedFilter === filter.value}
            secondary
          >
            {filter.label}
          </FilterBadge>
        ))}
      </div>

      {opportunities != null && (
        <div className="mt-8">
          <Select
            label="Opportunity"
            value={opportunityId}
            onChange={(event) => filterByOpportunity(event.target?.value, setOpportunityId)}
          >
            {[
              <option value={0} key="0">
                All opportunities
              </option>,
              ...opportunities.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              )),
            ]}
          </Select>
        </div>
      )}
    </div>
  );
};

export default CandidateFilters;
