import { createAction, DELETE, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    id: number;
  };
  success: never;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(DELETE, `/employers/team_members/${params.id}`)();
