import React, { FC } from 'react';

import { Outlet } from 'react-router-dom';
import { Footer } from '#components/Index';

import { EmployerNavbar } from './elements/EmployerNavbar';
import { useUserDetails } from '#src/AppData';

interface Props {
  withFooter?: boolean;
  className?: string;
}
const EmployerLayout: FC<Props> = ({ withFooter = false, className = 'bg-gray-100' }) => {
  const context = useUserDetails();

  return (
    <>
      <EmployerNavbar />
      <main className={className}>
        <Outlet context={context} />
      </main>
      {withFooter && <Footer />}
    </>
  );
};

export default EmployerLayout;
