import React, { useState } from 'react';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  initiallyExpanded?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ title, initiallyExpanded = false, children }) => {
  const [isOpen, setIsOpen] = useState(initiallyExpanded);

  const toggleAccordion = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="rounded-lg mb-4">
      <button
        onClick={toggleAccordion}
        className="w-full rounded-md flex justify-between items-center px-4 py-2 hover:bg-gray-200 focus:outline-none"
      >
        <h5>{title}</h5>
        <svg
          className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width="20"
          height="20"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && <div className="px-4 py-2 border-t border-gray-200">{children}</div>}
    </div>
  );
};

export default Accordion;
