import React, { FC } from 'react';
import { paths } from '#pages/paths';
import { Button } from '#src/components/Index';
import styles from '#pages/talentPages/opportunities/index/elements/OpportunityNavbar.module.css';

interface PotentialCandidatesNavbarProps {
  candidatesCount: number;
  interviewsCount: number;
}

const PotentialCandidatesNavbar: FC<PotentialCandidatesNavbarProps> = ({ candidatesCount, interviewsCount }) => {
  return (
    <div className="flex justify-between border-b-2 border-gray-200 mb-4">
      <div className="flex gap-x-4 justify-center w-full md:gap-x-8 md:justify-normal">
        <Button
          variant="link"
          className={[styles.navLink, styles.navLinkActive].join(' ')}
          to={paths.employerPotentialCandidates()}
        >
          Pending Review
          <span className="flex items-center justify-center text-green-900 bg-green-200 min-w-[1.25rem] w-auto h-5 rounded-sm px-1">
            {candidatesCount}
          </span>
        </Button>
        <Button variant="link" className={styles.navLink} to={paths.employerInterviews()}>
          Interviews
          <span className="flex items-center justify-center text-green-900 bg-green-200 min-w-[1.25rem] w-auto h-5 rounded-sm px-1">
            {interviewsCount}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default PotentialCandidatesNavbar;
