import React, { FC } from 'react';
import { Button } from '#src/components/Index';
import { useGetOpportunityCounters } from '#root/src/pages/talentPages/opportunities/index/data/OpportunityCountersData';
import styles from './OpportunityNavbar.module.css';

interface OpportunityNavbarProps {
  opportunitySection: 'all' | 'handpicked' | 'bookmarked';
  changeOpportunitySection: (section: 'all' | 'handpicked' | 'bookmarked') => void;
}

const OpportunityNavbar: FC<OpportunityNavbarProps> = ({ opportunitySection, changeOpportunitySection }) => {
  const { data: counters } = useGetOpportunityCounters();

  return (
    <div className="flex justify-between border-b-2 border-gray-200 mb-4">
      <div className="flex gap-x-4 justify-center w-full md:gap-x-8 md:justify-normal">
        <Button
          variant="link"
          className={`${styles.navLink} ${opportunitySection === 'handpicked' ? styles.navLinkActive : ''}`}
          onClick={() => changeOpportunitySection('handpicked')}
        >
          Hand-picked
          <span className="flex items-center justify-center text-green-900 bg-green-200 min-w-[1.25rem] w-auto h-5 rounded-sm px-1">
            {counters?.handpickedCount ?? 0}
          </span>
        </Button>
        <Button
          variant="link"
          className={`${styles.navLink} ${opportunitySection === 'all' ? styles.navLinkActive : ''}`}
          onClick={() => changeOpportunitySection('all')}
        >
          All Opportunities
        </Button>
      </div>
      <div className="hidden md:flex">
        <Button
          variant="link"
          className={`${styles.navLink} ${opportunitySection === 'bookmarked' ? styles.navLinkActive : ''}`}
          onClick={() => changeOpportunitySection('bookmarked')}
        >
          Saved
          <span className="flex items-center justify-center text-green-900 bg-green-200 min-w-[1.25rem] w-auto h-5 rounded-sm px-1">
            {counters?.bookmarkedCount ?? 0}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default OpportunityNavbar;
