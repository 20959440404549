import React, { FC, useState, useEffect } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { AutocompleteOption } from '#src/types';
import * as apiAutocompletePrivacyCompaniesOrEducationInstitutes from '#api/endpoints/autocomplete/privacyCompaniesOrEducationInstitutes';
import OptionBadge from '#components/Form/Inputs/MultiStringInput/OptionBadge';
import { TextInput, AutocompleteInput } from '#components/Form/Index';

interface TeamMemberInputProps {
  memberNumber: number;
  initialBackgrounds: AutocompleteOption[];
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
}

const TeamMemberInput: FC<TeamMemberInputProps> = ({ memberNumber, initialBackgrounds, register, setValue }) => {
  const [autocompleteValue, setAutocompleteValue] = useState<string>('');
  const [backgrounds, setBackgrounds] = useState<AutocompleteOption[]>(initialBackgrounds);

  // Initial set of backgrounds
  useEffect(() => {
    setValue(
      `teamMember${memberNumber}BackgroundIds`,
      initialBackgrounds.map((background) => background.id)
    );
  }, [setValue, memberNumber, initialBackgrounds]);

  const updateFormBackgrounds = (backgrounds: AutocompleteOption[]) => {
    setValue(
      `teamMember${memberNumber}BackgroundIds`,
      backgrounds.map((background) => background.id)
    );
  };

  const selectHandler = (selected: AutocompleteOption) => {
    setAutocompleteValue('');

    if (backgrounds.find((background) => background.id === selected.id) != null) {
      const updatedBackgrounds = backgrounds.filter((background) => background.id !== selected.id);
      setBackgrounds(updatedBackgrounds);
      updateFormBackgrounds(updatedBackgrounds);
      return;
    }

    const updatedBackgrounds = [...backgrounds, selected];

    setBackgrounds(updatedBackgrounds);
    updateFormBackgrounds(updatedBackgrounds);
  };

  const handleBadgeClick = (id: string) => {
    const updatedBackgrounds = backgrounds.filter((background) => background.id !== id);
    setBackgrounds(updatedBackgrounds);
    updateFormBackgrounds(updatedBackgrounds);
  };

  return (
    <div className="flex flex-col flex-grow gap-y-2">
      <TextInput
        label={`Full name (Member ${memberNumber})`}
        type="text"
        {...register(`teamMember${memberNumber}Name`)}
      />
      <TextInput
        label={`Job title (Member ${memberNumber})`}
        type="text"
        {...register(`teamMember${memberNumber}Position`)}
      />

      <AutocompleteInput
        label="Backgrounds"
        value={autocompleteValue}
        onChange={(name) => setAutocompleteValue(name)}
        autocompleteRequest={apiAutocompletePrivacyCompaniesOrEducationInstitutes.request}
        selectHandler={selectHandler}
        recordsLimit={5}
        placeholder="Start typing to find companies or universities"
      />

      <div className="flex flex-wrap max-w-full pt-2">
        {backgrounds.map((background) => (
          <OptionBadge
            key={background.id}
            option={background.name}
            handleBadgeClick={() => handleBadgeClick(background.id.toString())}
            negative={false}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamMemberInput;
