import * as apiGetInterviewingCandidates from '#api/endpoints/employers/talentPool/getInterviewingCandidates';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employers = 'employers',
  talentPool = 'talentPool',
  candidates = 'candidates',
  interviewing = 'interviewing',
}

type GetApiPayload = apiGetInterviewingCandidates.Type['success']['payload'];
export const useGetInterviewingCandidatesData = (opportunityId?: number, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.employers, KeysEnum.talentPool, KeysEnum.candidates, KeysEnum.interviewing, opportunityId].filter(
      (item) => item != null
    ),
    async () => await apiGetInterviewingCandidates.request({ opportunityId }),
    {
      ...options,
    }
  );
