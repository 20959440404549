import React, { FC, useMemo } from 'react';
import { JobHistoryEntry } from '#src/types';
import { alternativeFormattedDateFromISO } from '#src/lib/dateParsers';
import DefaultHistoryEntry from './DefaultHistoryEntry';
import PutForwardHistoryEntry from './PutForwardHistoryEntry';
import SentMessage from './SentMessage';
import ReceivedMessage from './ReceivedMessage';

interface JobHistoryItemProps extends JobHistoryEntry {
  isLast: boolean;
  companyLogoUrl: string;
}

const JobHistoryItem: FC<JobHistoryItemProps> = ({ type, date, message, isLast, companyLogoUrl }) => {
  const parsedDate = useMemo(() => alternativeFormattedDateFromISO(date), [date]);

  switch (type) {
    case 'candidate_put_forward':
      return <PutForwardHistoryEntry date={parsedDate} message={message} isLast={isLast} />;
    case 'talent_message':
      return <SentMessage date={parsedDate} message={message} isLast={isLast} />;
    case 'employer_message':
      return <ReceivedMessage date={parsedDate} message={message} isLast={isLast} avatarUrl={companyLogoUrl} />;
    default:
      return <DefaultHistoryEntry date={parsedDate} message={message} isLast={isLast} />;
  }
};

export default JobHistoryItem;
