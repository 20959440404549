import React, { FC, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { isApiError } from '#src/lib/parseFormsErrors';
import { paths, parseParamsToLinkSearch, signInRedirectUrl } from '#pages/paths';
import { TextInput } from '#components/Form/Index';
import { Button, ErrorAlert } from '#components/Index';
import { useSignInController } from './FormController';
import NotApprovedMessage from './NotApprovedMessage';
import ActiveYourAccountMessage from '../password/activateAccount/Message';

const SignInForm: FC = () => {
  const [searchParams] = useSearchParams();
  const redirectUrlFromQueryParams = searchParams.get('redirectUrl');

  const [isNotApproved, setIsNotApproved] = useState(false);
  const [isNotActivated, setIsNotActivated] = useState(false);
  const {
    form: {
      register,
      handleSubmit,
      formState: { errors },
      watch,
    },
    submitForm,
    baseErrors,
  } = useSignInController();

  const email = watch('email');

  if (isNotApproved) {
    return <NotApprovedMessage />;
  }
  if (isNotActivated) {
    return <ActiveYourAccountMessage email={email} />;
  }

  // https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164
  // https://github.com/react-hook-form/react-hook-form/issues/3041
  // https://github.com/react-hook-form/react-hook-form/discussions/7576#discussioncomment-1996675
  // https://github.com/iamJoeTaylor/vanilla-autofill-event/issues/4 -- Chrome security policy does not allow detection of password autofills until user interacts with the page in some way
  // triggering onSubmit after the render skips the chrome security autofill initial empty values problem
  const onSubmit = handleSubmit((values) =>
    setTimeout(() => {
      void (async () => {
        try {
          await submitForm(values);
        } catch (e) {
          if (isApiError(e)) {
            if (e.status === 403 || e.status === 418) {
              setIsNotApproved(true);
            }
          }
        }
      })();
    })
  );

  return (
    <>
      <h1 className="text-display-sm font-serif">Sign in to Hiperpool</h1>
      <form onSubmit={onSubmit} className="w-full grid gap-6 text-sm text-gray-700 font-medium">
        <TextInput
          label="Email address"
          type="email"
          error={errors.email?.message}
          autoComplete="username"
          {...register('email')}
        />
        <TextInput
          label="Password"
          type="password"
          error={errors.password?.message}
          autoComplete="current-password"
          {...register('password')}
        />
        <Link to={paths.resetPassword()} className="link">
          Forgot your password?
        </Link>
        <ErrorAlert text={baseErrors} />
        <Button type="submit" className="xs:mx-auto" size="large">
          Sign in
        </Button>
        <p className="text-center">
          Don’t have an account?{' '}
          <Link
            to={
              paths.signUp() + parseParamsToLinkSearch({ redirectUrl: signInRedirectUrl(redirectUrlFromQueryParams) })
            }
            className="link"
          >
            Sign up for free
          </Link>
        </p>
      </form>
    </>
  );
};

export default SignInForm;
